import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { HiOutlineDownload } from "react-icons/hi";

import api from "../../../services/api";
import Loader from "../../../components/Loader";
import Switch from "../../../components/Switch";
import Documents from "./Documents";
import Infos from "./Infos";
import Form from "./Form";
import Report from "./Report";
import Valuation from "./Valuation";

const PLAN_SPAN = {
  ACCELERATED: <span className="bg-green-200 rounded p-1 text-sm font-semibold">Accéléré</span>,
  CERTIFIED: <span className="bg-yellow-200 rounded p-1 text-sm font-semibold">Certifié</span>,
};

const StartUpView = () => {
  const location = useLocation();
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [downloading, setDownloading] = useState(false);
  const tab = location.pathname.split(id).pop();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (!res.ok) throw res;
        res.data.created_at = new Date(res.data.created_at);
        res.data.updated_at = new Date(res.data.updated_at);
        setCompany(res.data);
        if (!res.data.notation_total_score) toast.error("La notation n'a pas encore été calculée : allez dans l'onglet Notation");
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de la récupération des informations");
      }
    };
    fetchData();
  }, []);

  const handleChange = async (values) => {
    try {
      const res = await api.put(`/company/${company._id}`, values);
      if (!res.ok) throw res;
      toast.success("Les informations ont été mises à jour");
      setCompany(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de la mise à jour des informations");
    }
  };

  const handleGeneratePdf = async () => {
    setDownloading(true);
    try {
      try {
        // Move the template to the backend
        const response = await api.post(`/company/${id}/pdf`);
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "valorisation.pdf"; // temporary
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error(error);
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
    setDownloading(false);
  };

  if (!company) return <></>;

  return (
    <div className="flex flex-col p-8">
      <header className="w-full mb-8 flex justify-between">
        <div className="flex items-center gap-3">
          <h1 className="text-3xl font-semibold text-black mb-2 mr-4">Valorisation de {company.company_name}</h1>
          <span className="bg-green-200 p-2 rounded text-sm text-black">TME / PME</span>
          {company.valuation_plan && PLAN_SPAN[company.valuation_plan]}
        </div>

        <div className="flex items-center gap-4">
          <button type="button" className="primary-button" onClick={handleGeneratePdf} disabled={downloading}>
            {downloading ? (
              <Loader color="border-l-white" size="w-5 h-5 border-[2px] rounded-full" />
            ) : (
              <>
                Générer le rapport
                <HiOutlineDownload className="w-5 ml-2" />
              </>
            )}
          </button>
          {company.valuation_document && (
            <a href={company.valuation_document.file_url} target="_blank" rel="noreferrer" className="empty-button">
              <HiOutlineDownload className="w-5" />
            </a>
          )}
        </div>
      </header>

      <div className="flex justify-end gap-4 mb-4">
        <div className="space-y-4">
          <label className="text-black text-sm font-semibold">Paiement</label>
          <div className="flex justify-center">
            <Switch checked={company.valuation_has_paid} onChange={(v) => handleChange({ valuation_has_paid: v, valuation_step: v ? "FORM" : "PRODUCT" })} />
          </div>
        </div>
        <div className="space-y-2">
          <label className="text-black text-sm font-semibold">Plan</label>
          <select className="select" value={company.valuation_plan} onChange={(e) => handleChange({ valuation_plan: e.target.value })}>
            <option value="ACCELERATED">Accélérée</option>
            <option value="CERTIFIED">Certifiée</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="text-black text-sm font-semibold">Étape en cours</label>
          <select className="select" value={company.valuation_step} onChange={(e) => handleChange({ valuation_step: e.target.value })}>
            <option value="PRODUCT">Choix du produit</option>
            <option value="FORM">Formulaire</option>
            <option value="DOCUMENTS">Documents</option>
            <option value="INTERVIEW">Entretien</option>
            <option value="INTERVIEW_SCHEDULED">Entretien programmé</option>
            <option value="REPORT">Rapport</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="text-black text-sm font-semibold">Statut</label>
          <select className="select" value={company.valuation_status} onChange={(e) => handleChange({ valuation_status: e.target.value })}>
            <option value="PENDING">En attente</option>
            <option value="IN_PROGRESS">En cours</option>
            <option value="FINISHED">Finie</option>
          </select>
        </div>
      </div>
      <div className="flex gap-4">
        <Link to="" className={`rounded-lg px-4 py-2 ${tab === "" ? "bg-gray-100" : "border border-secondary"}`}>
          Informations
        </Link>
        <Link to="valuation" className={`rounded-lg px-4 py-2 ${tab === "/valuation" ? "bg-gray-100" : "border border-secondary"}`}>
          Valorisation
        </Link>
        <Link to="form" className={`rounded-lg px-4 py-2 ${tab === "/form" ? "bg-gray-100" : "border border-secondary"}`}>
          Formulaire
        </Link>
        <Link to="documents" className={`rounded-lg px-4 py-2 ${tab === "/documents" ? "bg-gray-100" : "border border-secondary"}`}>
          Documents
        </Link>
        <Link to="report" className={`rounded-lg px-4 py-2 ${tab === "/report" ? "bg-gray-100" : "border border-secondary"}`}>
          Rapport
        </Link>
        <Link to="raw" className={`rounded-lg px-4 py-2 ${tab === "/raw" ? "bg-gray-100" : "border border-secondary"}`}>
          Données brutes
        </Link>
      </div>
      <div className="p-4">
        <Routes>
          <Route path="/" element={<Infos company={company} />} />
          <Route path="/valuation" element={<Valuation company={company} setCompany={setCompany} />} />
          <Route path="/form" element={<Form company={company} />} />
          <Route path="/documents" element={<Documents company={company} />} />
          <Route path="/report" element={<Report company={company} setCompany={setCompany} />} />
          <Route path="/raw" element={<Raw company={company} />} />
        </Routes>
      </div>
    </div>
  );
};

const Raw = ({ company }) => (
  <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
    <pre className="text-white text-sm">{JSON.stringify(company, null, 2)}</pre>
  </div>
);

export default StartUpView;
