/**
 * Valorisation methods for SMEs
 * https://docs.google.com/document/d/1tajhftZ7OMjQR70Wz2V7IVmuudXsehnHKY43uk-LLVs/ebit#heading=h.1u4v3kkk5nx7
 *
 * Translation:
 * Excédent brut d'exploitation (EBE) = Earnings before interest, taxes, depreciation, and amortization (EBITDA)
 * Results d'exploitation = Operating income
 * Resultat net = Net income
 * Trésorerie = Cash flow
 * FTD = Free cash flow
 * BFR = Working capital
 */

import { ENVIRONMENT } from "../services/config";

const PROJECTION_YEAR = 9;
const HISTORIC_YEAR = 4;
const TOTAL_YEAR = PROJECTION_YEAR + HISTORIC_YEAR;

// Méthode DCF
export const dcf = (company) => {
  const debt = company.debt_3 || 0;
  const treasury = company.treasury_3 || 0;

  const addings = [company.addings_0, company.addings_1, company.addings_2, company.addings_3];
  const adjustments = [company.adjustments_0, company.adjustments_1, company.adjustments_2, company.adjustments_3];
  const turnovers = [
    company.turnover_0 || 0,
    company.turnover_1 || 0,
    company.turnover_2 || 0,
    company.turnover_3 || 0,
    company.turnover_4 || 0,
    company.turnover_5 || 0,
    company.turnover_6 || 0,
    company.turnover_7 || 0,
    company.turnover_8 || 0,
    company.turnover_9 || 0,
    company.turnover_10 || 0,
    company.turnover_11 || 0,
    company.turnover_12 || 0,
  ];

  const turnoversProjections = [];
  const turnoversGrowth = [];

  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (i < 4) {
      turnoversProjections.push(turnovers[i]);
    } else {
      if (turnovers[i]) turnoversProjections.push(turnovers[i]);
      else {
        const a = turnoversProjections[i - 1] * (1 + company.dcf_industry_growth_rate * company.dcf_evolution_factor) * (1 - company.dcf_arithmetic_weight);
        const b = (turnoversProjections[i - 1] + (turnoversProjections[i - 1] - turnoversProjections[i - 2]) * company.dcf_evolution_factor) * company.dcf_arithmetic_weight;
        turnoversProjections.push(a + b);
      }
    }
    if (turnoversProjections[i] && turnoversProjections[i - 1]) turnoversGrowth.push((turnoversProjections[i] - turnoversProjections[i - 1]) / turnoversProjections[i - 1]);
    else turnoversGrowth.push(0);
  }

  const ebitdas = [
    company.ebitda_0 || 0,
    company.ebitda_1 || 0,
    company.ebitda_2 || 0,
    company.ebitda_3 || 0,
    company.ebitda_4 || 0,
    company.ebitda_5 || 0,
    company.ebitda_6 || 0,
    company.ebitda_7 || 0,
    company.ebitda_8 || 0,
    company.ebitda_9 || 0,
    company.ebitda_10 || 0,
    company.ebitda_11 || 0,
    company.ebitda_12 || 0,
  ];

  const ebitdaMargins = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (i < 4) {
      if (turnovers[i] !== 0) ebitdaMargins.push((ebitdas[i] - adjustments[i] + addings[i]) / turnovers[i]);
      else ebitdaMargins.push(0);
    } else {
      if (!isNaN(ebitdas[i]) && !isNaN(turnoversProjections[i])) ebitdaMargins.push(ebitdas[i] / turnoversProjections[i]);
      else ebitdaMargins.push(0);
    }
  }

  const ebitdaMarginAverage = ebitdaMargins.slice(0, 4).reduce((a, b) => a + b, 0) / 4;

  const ebitdaMarginProjections = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (i < 4) ebitdaMarginProjections.push(ebitdaMargins[i]);
    else if (i === 4) {
      ebitdaMarginProjections.push(ebitdaMargins[i - 1] - (ebitdaMargins[i - 1] - ebitdaMarginAverage) / 9);
    } else {
      if (ebitdaMarginProjections[i - 2] === 0) ebitdaMarginProjections.push(0);
      else {
        const a =
          (1 - ebitdaMarginAverage) * ebitdaMarginProjections[i - 1] * (1 + (ebitdaMarginProjections[i - 1] / ebitdaMarginProjections[i - 2] - 1) * company.dcf_evolution_factor);
        const b = ebitdaMarginAverage * (ebitdaMarginProjections[i - 1] + (ebitdaMarginProjections[i - 1] - ebitdaMarginProjections[i - 2]) * company.dcf_evolution_factor);
        ebitdaMarginProjections.push(a + b);
      }
    }
  }

  const adujestedEbitdaProjections = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (ebitdas[i]) adujestedEbitdaProjections.push(ebitdas[i] - (adjustments[i] || 0) + (addings[i] || 0));
    else {
      adujestedEbitdaProjections.push(turnoversProjections[i] * ebitdaMarginProjections[i]);
    }
  }

  const dnas = [
    company.dna_0 || 0,
    company.dna_1 || 0,
    company.dna_2 || 0,
    company.dna_3 || 0,
    company.dna_4 || 0,
    company.dna_5 || 0,
    company.dna_6 || 0,
    company.dna_7 || 0,
    company.dna_8 || 0,
    company.dna_9 || 0,
    company.dna_10 || 0,
    company.dna_11 || 0,
    company.dna_12 || 0,
  ];
  // const a = dnas[3] * 15;
  // const b = dnas[2] * 4;
  // const c = dnas[1];
  // const a = turnoversProjections[3] !== 0 ? dnas[3] * 15 : 0;
  // const b = turnoversProjections[2] !== 0 ? dnas[2] * 4 : 0;
  // const c = turnoversProjections[1] !== 0 ? dnas[1] : 0;
  const dnaMarginProjections = [];
  const dnaProjections = [];
  const a = turnoversProjections[3] !== 0 ? dnas[3] / turnoversProjections[3] : 0;
  const b = turnoversProjections[2] !== 0 ? dnas[2] / turnoversProjections[2] : 0;
  const c = turnoversProjections[1] !== 0 ? dnas[1] / turnoversProjections[1] : 0;
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (dnas[i]) {
      dnaProjections.push(dnas[i]);
      dnaMarginProjections.push(dnas[i] / turnoversProjections[i]);
    } else {
      const d = (a * 15 + b * 4 + c) / 20;
      dnaMarginProjections.push(d);
      dnaProjections.push(d * turnoversProjections[i]);
    }
  }

  const otherCharges = [
    company.other_charges_0 || 0,
    company.other_charges_1 || 0,
    company.other_charges_2 || 0,
    company.other_charges_3 || 0,
    company.other_charges_4 || 0,
    company.other_charges_5 || 0,
    company.other_charges_6 || 0,
    company.other_charges_7 || 0,
    company.other_charges_8 || 0,
    company.other_charges_9 || 0,
    company.other_charges_10 || 0,
    company.other_charges_11 || 0,
    company.other_charges_12 || 0,
  ];
  const otherChargeProjections = [];

  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (otherCharges[i]) otherChargeProjections.push(otherCharges[i]);
    else if (i < 3) otherChargeProjections.push(0);
    else {
      if (turnoversProjections[i - 1] === 0 || turnoversProjections[i - 2] === 0 || turnoversProjections[i - 3] === 0) otherChargeProjections.push(0);
      else {
        const a = (otherChargeProjections[i - 1] * 15) / turnoversProjections[i - 1];
        const b = (otherChargeProjections[i - 2] * 4) / turnoversProjections[i - 2];
        const c = otherChargeProjections[i - 3] / turnoversProjections[i - 3];
        otherChargeProjections.push(((a + b + c) * turnoversProjections[i]) / 20);
      }
    }
  }

  const operatingIncomes = [
    company.operating_income_0 || 0,
    company.operating_income_1 || 0,
    company.operating_income_2 || 0,
    company.operating_income_3 || 0,
    company.operating_income_4 || 0,
    company.operating_income_5 || 0,
    company.operating_income_6 || 0,
    company.operating_income_7 || 0,
    company.operating_income_8 || 0,
    company.operating_income_9 || 0,
    company.operating_income_10 || 0,
    company.operating_income_11 || 0,
    company.operating_income_12 || 0,
  ];

  const operatingIncomeProjections = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (operatingIncomes[i]) operatingIncomeProjections.push(operatingIncomes[i]);
    else operatingIncomeProjections.push(adujestedEbitdaProjections[i] - dnaProjections[i] - otherChargeProjections[i]);
  }

  const societyTaxes = [];
  for (let i = 4; i < TOTAL_YEAR; i++) {
    if (operatingIncomeProjections[i] < 0) {
      societyTaxes.push(0);
    } else if (turnoversProjections[i] < 10000000 && operatingIncomeProjections[i] > 200000) {
      societyTaxes.push(200000 * 0.19 + (operatingIncomeProjections[i] - 200000) * company.taxes_rate);
    } else {
      societyTaxes.push(operatingIncomeProjections[i] * 0.19);
    }
  }

  // const societyTaxes = [];
  // for (let i = 4; i < TOTAL_YEAR; i++) {
  //   if (turnoversProjections[i] < 10000000 && operatingIncomeProjections[i] > 42500) societyTaxes.push(42500 * 0.15 + (operatingIncomeProjections[i] - 42500) * company.taxes_rate);
  //   else societyTaxes.push(operatingIncomeProjections[i] * 0.15);
  // }

  const investments = [
    company.investments_0 || 0,
    company.investments_1 || 0,
    company.investments_2 || 0,
    company.investments_3 || 0,
    company.investments_4 || 0,
    company.investments_5 || 0,
    company.investments_6 || 0,
    company.investments_7 || 0,
    company.investments_8 || 0,
    company.investments_9 || 0,
    company.investments_10 || 0,
    company.investments_11 || 0,
    company.investments_12 || 0,
  ];
  const investmentAverage = company.turnover_0
    ? 0.1 * (company.investments_0 / company.turnover_0)
    : 0 + company.turnover_1
    ? 0.1 * (company.investments_1 / company.turnover_1)
    : 0 + company.turnover_2
    ? 0.3 * (company.investments_2 / company.turnover_2)
    : 0 + company.turnover_3
    ? 0.5 * (company.investments_3 / company.turnover_3)
    : 0;

  const investmentProjections = [];
  for (let i = 4; i < TOTAL_YEAR; i++) {
    if (investments[i]) investmentProjections.push(investments[i]);
    else investmentProjections.push(turnoversProjections[i] * investmentAverage);
  }

  const working_capitals_operating_2 = 0; //-106834; // TOCHECK - where are these values coming from? (case rouge dans Data a remplir sur l'excel)
  const working_capitals_operating_3 = 0; //-70960; // TOCHECK - where are these values coming from? (case rouge dans Data a remplir sur l'excel)
  const rate_working_capitals_operating_2 = turnoversProjections[2] !== 0 ? working_capitals_operating_2 / turnoversProjections[2] : 0;
  const rate_working_capitals_operating_3 = turnoversProjections[3] !== 0 ? working_capitals_operating_3 / turnoversProjections[3] : 0;

  const workingCapitalProjections = [
    company.working_capital_0 || 0,
    company.working_capital_1 || 0,
    company.working_capital_2 || 0,
    company.working_capital_3 || 0,
    company.working_capital_4 || 0,
    company.working_capital_5 || 0,
    company.working_capital_6 || 0,
    company.working_capital_7 || 0,
    company.working_capital_8 || 0,
    company.working_capital_9 || 0,
    company.working_capital_10 || 0,
    company.working_capital_11 || 0,
    company.working_capital_12 || 0,
  ];

  for (let i = 4; i < TOTAL_YEAR; i++) {
    if (!workingCapitalProjections[i]) workingCapitalProjections[i] = turnoversProjections[i] / 6;
  }

  const netWorkingCapital = [workingCapitalProjections[0]];
  for (let i = 1; i < TOTAL_YEAR; i++) {
    netWorkingCapital.push(workingCapitalProjections[i] - workingCapitalProjections[i - 1]);
  }

  const freeCashFlows = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    freeCashFlows.push(adujestedEbitdaProjections[i + 4] - societyTaxes[i] - investmentProjections[i] - netWorkingCapital[i + 4]);
  }

  const terminalValues = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    if (company.dcf_wacc - company.dcf_infinite_growth_rate === 0) terminalValues.push(0);
    else terminalValues.push((freeCashFlows[i] * (1 + company.dcf_infinite_growth_rate)) / (company.dcf_wacc - company.dcf_infinite_growth_rate));
  }

  const actualizedFreeCashFlows = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    if (Math.pow(1 + company.dcf_wacc, i + 1) === 0) actualizedFreeCashFlows.push(0);
    else actualizedFreeCashFlows.push(freeCashFlows[i] / Math.pow(1 + company.dcf_wacc, i + 1));
  }

  const actualizedTerminalValue = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    if (Math.pow(1 + company.dcf_wacc, i + 1) === 0) actualizedTerminalValue.push(0);
    else actualizedTerminalValue.push(terminalValues[i] / Math.pow(1 + company.dcf_wacc, i + 1));
  }

  const equityValues = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    const freeCashFlowSum = actualizedFreeCashFlows.slice(0, i + 1).reduce((a, b) => a + b, 0);
    equityValues.push(freeCashFlowSum + actualizedTerminalValue[i] - (debt - treasury));
  }

  const details = {
    turnoversProjections,
    turnoversGrowth,
    ebitdaMarginAverage,
    ebitdaMarginProjections,
    adujestedEbitdaProjections,
    dnaMarginProjections,
    dnaProjections,
    otherChargeProjections,
    operatingIncomeProjections,
    societyTaxes,
    investmentAverage,
    investmentProjections,
    working_capitals_operating_2,
    working_capitals_operating_3,
    rate_working_capitals_operating_2,
    rate_working_capitals_operating_3,
    workingCapitalProjections,
    netWorkingCapital,
    freeCashFlows,
    terminalValues,
    actualizedFreeCashFlows,
    actualizedTerminalValue,
  };
  company.valuation_dcf = equityValues[company.dcf_year];
  company.valuation_dcf_array = freeCashFlows;
  return { result: equityValues, details };
};

// Méthode EBE
export const ebitda = (company) => {
  const SECTORS = [
    "Biens de consommation",
    "Biens d’équipement",
    "Distribution",
    "Energie / Service publics",
    "Immobilier",
    "Industrie automobile",
    "Matériaux",
    "Média / Communication",
    "Santé / médical",
    "Services aux consommateurs",
    "Services aux entreprises",
    "Technologies de l’information",
    "Télécommunication",
    "Transport & logistique",
    "Services financiers",
  ];
  const sectorMultiples = [11.9, 12.1, 7.9, 8.6, 16.4, 9.3, 11.3, 9.8, 16.5, 11.8, 12.2, 14.8, 8.3, 10.6, 1];

  const adjustedEbitda0 = (company.ebitda_0 || 0) + (company.adjustments_0 || 0) - (company.addings_0 || 0);
  const adjustedEbitda1 = (company.ebitda_1 || 0) + (company.adjustments_1 || 0) - (company.addings_1 || 0);
  const adjustedEbitda2 = (company.ebitda_2 || 0) + (company.adjustments_2 || 0) - (company.addings_2 || 0);
  const adjustedEbitda3 = (company.ebitda_3 || 0) + (company.adjustments_3 || 0) - (company.addings_3 || 0);
  const adjustedEbitda = [adjustedEbitda0, adjustedEbitda1, adjustedEbitda2, adjustedEbitda3];
  const ebitdaAverage = adjustedEbitda1 * company.ebitda_weighting_1 + adjustedEbitda2 * company.ebitda_weighting_2 + adjustedEbitda3 * company.ebitda_weighting_3;

  const debt = company.debt_3 || 0;
  const treasury = company.treasury_3 || 0;

  const ebitda_factor = company.ebitda_factor || sectorMultiples[SECTORS.indexOf(company.company_sector)];

  const details = {
    adjustedEbitda,
    ebitdaAverage,
    debt,
    ebitda_factor,
  };

  const valorisation = ebitdaAverage * ebitda_factor * company.ebitda_risk_factor - (debt - treasury);
  company.valuation_ebitda = valorisation;
  company.valuation_ebitda_array = adjustedEbitda;
  company.ebitda_final_factor = ebitda_factor;
  company.ebitda_years = [company.ebitda_weighting_1, company.ebitda_weighting_2, company.ebitda_weighting_3].filter((value) => value !== 0).length;

  return { result: valorisation, details };
};

// Méthode EBIT
export const ebit = (company) => {
  const SECTORS = [
    "Biens de consommation",
    "Biens d’équipement",
    "Distribution",
    "Energie / Service publics",
    "Immobilier",
    "Industrie automobile",
    "Matériaux",
    "Média / Communication",
    "Santé / médical",
    "Services aux consommateurs",
    "Services aux entreprises",
    "Technologies de l’information",
    "Télécommunication",
    "Transport & logistique",
    "Services financiers",
  ];
  const sectorMultiples = [15.5, 15.2, 10.1, 11.8, 18.6, 13.5, 15, 14.4, 20.8, 14.8, 16, 18.8, 13.9, 14];

  const adjustedEbitda0 = (company.ebitda_0 || 0) + (company.adjustments_0 || 0) - (company.addings_0 || 0);
  const adjustedEbitda1 = (company.ebitda_1 || 0) + (company.adjustments_1 || 0) - (company.addings_1 || 0);
  const adjustedEbitda2 = (company.ebitda_2 || 0) + (company.adjustments_2 || 0) - (company.addings_2 || 0);
  const adjustedEbitda3 = (company.ebitda_3 || 0) + (company.adjustments_3 || 0) - (company.addings_3 || 0);
  const ebit0 = adjustedEbitda0 - (company.dna_0 || 0) + (company.other_charges_0 || 0);
  const ebit1 = adjustedEbitda1 - (company.dna_1 || 0) + (company.other_charges_1 || 0);
  const ebit2 = adjustedEbitda2 - (company.dna_2 || 0) + (company.other_charges_2 || 0);
  const ebit3 = adjustedEbitda3 - (company.dna_3 || 0) + (company.other_charges_3 || 0);
  const dna = [company.dna_0 || 0, company.dna_1 || 0, company.dna_2 || 0, company.dna_3 || 0];
  const debt = company.debt_3 || 0;
  const treasury = company.treasury_3 || 0;
  const otherCharges = [company.other_charges_0 || 0, company.other_charges_1 || 0, company.other_charges_2 || 0, company.other_charges_3 || 0];
  const ebitAverage = ebit1 * (company.ebit_weighting_1 || 0) + ebit2 * (company.ebit_weighting_2 || 0) + ebit3 * (company.ebit_weighting_3 || 0);
  const adjustedEbitda = [adjustedEbitda0, adjustedEbitda1, adjustedEbitda2, adjustedEbitda3];
  const ebit = [ebit0, ebit1, ebit2, ebit3];

  const ebit_factor = company.ebit_factor || sectorMultiples[SECTORS.indexOf(company.company_sector)];
  const details = {
    dna,
    otherCharges,
    adjustedEbitda,
    ebit,
    ebitAverage,
    ebit_factor,
  };

  company.valuation_ebit = ebitAverage * ebit_factor * company.ebit_risk_factor - (debt - treasury);
  company.valuation_ebit_array = ebit;
  company.ebit_final_factor = ebit_factor;
  company.ebit_years = [company.ebit_weighting_1, company.ebit_weighting_2, company.ebit_weighting_3].filter((value) => value !== 0).length;
  return { result: company.valuation_ebit, details };
};

// Méthode patrimoniale
export const patrimoniale = () => {
  // TODO finish this
  const company = {
    ebe: [253001, 278301, 292216],
    loyer_entreprise: [300000, 300000, 300000],
    salaire_dirigeant: [0, 0, 0],
    participation: [0, 0, 0],
    crebit_bails: [0, 0, 0],
    depreciation_stocks: [0, 0, 0],
    loyer_moyen: [150000, 150000, 150000],
    salaire_moyen_dirigeant: [0, 0, 0],
    da_crebits_bails: [0, 0, 0],
    da: [136147, 152000, 153154],
    autres_produits: [-14000, -40000, -40000],
    weighting_en_fonction_des_annees: [0.1, 0.3, 0.6],
    multiple_ebe: 14.8,
    multiple_ebit: 18.8,
    facteur_risque: 1,
    dette: 1251006,
    treso: 72872,
    actif_immobilise: {
      immobilisations_incorporelles: [49000, 66510],
      immobilisations_corporelles: [131181, 117011],
      immobilisations_financieres: [45064],
    },
    actif_circulant: {
      stock_mp: [],
      avances_acomptes: [1393619],
      autres_creances: [155306],
      vmp: [32500],
      disponibilites: [586051],
      compte_regularisation: [],
      charges_constatees_avance: [26906],
    },
    reevaluations: {
      immobilisations_corporelles: 0,
      immobilisations_incorporelles: 0,
      immobilisations_financieres: 0,
      stock_mp: 0,
      avances_acomptes: 0,
      autres_creances: 0,
      vmp: 0,
      disponibilites: 0,
      compte_regularisation: 0,
      charges_constatees_avance: 0,
      emprunts_et_dettes: 0,
      produits_constatees_avance: 0,
      autres_dettes: 0,
      avances_acomptes_dettes: 0,
      fournisseurs: 0,
    },
    dettes: {
      emprunts_et_dettes: [500000, 50000, 6000],
      produits_constatees_avance: [],
      autres: [987624, 5058],
      avances_acomptes: [],
      fournisseurs: [498803],
    },
    fiscale: {
      multiple_pertinent: true,
      multiple_rentabilite_ebe: 0.85,
      multiple_theorique_industrie: 0.9,
      multiple_theorique_rentabilite: 1,
      multiple_theorique_asset: 1,
    },
    productivite: {
      taux: "aaa",
      taux_valeur: 0.0875,
      resultat_exploitation: [103131, 116854, 126301, 139062],
      ca: [4021677, 7073713, 7981084, 8370138],
      weighting: [0, 0.1, 0.3, 0.6],
      taux_investissement_sans_risque: 0.0293,
      prime_risque: 0.05,
    },
    capitalisation: {
      n_annees: 4,
      benefice_net: [25783, 29214, 31575, 34766],
      weighting: [0, 0.1, 0.3, 0.6],
      besoin_investissement: 3,
      secteur: 2.57,
      risque: 2,
      stabilite: 2,
      da: [125067, 136147, 152000, 153154],
      provisions: [0, 0, 0, 0],
      pourcentage_caf: [0.05, 0.05, 0],
      n_annees_remboursement: 9,
    },
  };
  // Calcul de la Somme 1

  const immobilisations_incorporelles = company.actif_immobilise.immobilisations_incorporelles;
  let somme_immobilisation_incoporelles = 0;
  for (let i = 0; i < immobilisations_incorporelles.length; i++) {
    somme_immobilisation_incoporelles += immobilisations_incorporelles[i];
  }

  const immobilisations_corporelles = company.actif_immobilise.immobilisations_corporelles;
  let somme_immobilisation_coporelles = 0;
  for (let i = 0; i < immobilisations_corporelles.length; i++) {
    somme_immobilisation_coporelles += immobilisations_corporelles[i];
  }

  const immobilisations_financieres = company.actif_immobilise.immobilisations_financieres;
  let somme_immobilisations_financieres = 0;
  for (let i = 0; i < immobilisations_financieres.length; i++) {
    somme_immobilisations_financieres += immobilisations_financieres[i];
  }

  const stock_mp = company.actif_circulant.stock_mp;
  let somme_stock_mp = 0;
  for (let i = 0; i < stock_mp.length; i++) {
    somme_stock_mp += stock_mp[i];
  }

  const avances_acomptes = company.actif_circulant.avances_acomptes;
  let somme_avances_acomptes = 0;
  for (let i = 0; i < avances_acomptes.length; i++) {
    somme_avances_acomptes += avances_acomptes[i];
  }

  const autres_creances = company.actif_circulant.autres_creances;
  let somme_autres_creances = 0;
  for (let i = 0; i < autres_creances.length; i++) {
    somme_autres_creances += autres_creances[i];
  }

  const vmp = company.actif_circulant.vmp;
  let somme_vmp = 0;
  for (let i = 0; i < vmp.length; i++) {
    somme_vmp += vmp[i];
  }

  const disponibilites = company.actif_circulant.disponibilites;
  let somme_disponibilites = 0;
  for (let i = 0; i < disponibilites.length; i++) {
    somme_disponibilites += disponibilites[i];
  }

  const compte_regularisation = company.actif_circulant.compte_regularisation;
  let somme_compte_regularisation = 0;
  for (let i = 0; i < compte_regularisation.length; i++) {
    somme_compte_regularisation += compte_regularisation[i];
  }

  const charges_constatees_avance = company.actif_circulant.charges_constatees_avance;
  let somme_charges_constatees_avance = 0;
  for (let i = 0; i < charges_constatees_avance.length; i++) {
    somme_charges_constatees_avance = charges_constatees_avance[i];
  }

  const somme_1 =
    somme_immobilisation_incoporelles +
    somme_immobilisation_coporelles +
    somme_immobilisations_financieres +
    somme_stock_mp +
    somme_avances_acomptes +
    somme_autres_creances +
    somme_vmp +
    somme_disponibilites +
    somme_compte_regularisation +
    somme_charges_constatees_avance;

  // Calcul de la Somme 2

  const somme_2 =
    company.reevaluations.immobilisations_corporelles +
    company.reevaluations.immobilisations_incorporelles +
    company.reevaluations.immobilisations_financieres +
    company.reevaluations.stock_mp +
    company.reevaluations.avances_acomptes +
    company.reevaluations.autres_creances +
    company.reevaluations.vmp +
    company.reevaluations.disponibilites +
    company.reevaluations.compte_regularisation +
    company.reevaluations.charges_constatees_avance;

  // Calcul de la Somme 3

  const emprunts_et_dettes = company.dettes.emprunts_et_dettes;
  let somme_emprunts_et_dettes = 0;
  for (let i = 0; i < emprunts_et_dettes.length; i++) {
    somme_emprunts_et_dettes += emprunts_et_dettes[i];
  }

  const produits_constatees_avance = company.dettes.produits_constatees_avance;
  let somme_produits_constatees_avance = 0;
  for (let i = 0; i < produits_constatees_avance.length; i++) {
    somme_produits_constatees_avance += produits_constatees_avance[i];
  }

  const autres = company.dettes.autres;
  let somme_autres = 0;
  for (let i = 0; i < autres.length; i++) {
    somme_autres += autres[i];
  }

  const avances_acomptes_dettes = company.dettes.avances_acomptes;
  let somme_avances_acomptes_dettes = 0;
  for (let i = 0; i < avances_acomptes_dettes.length; i++) {
    somme_avances_acomptes_dettes += avances_acomptes_dettes[i];
  }

  const fournisseurs = company.dettes.fournisseurs;
  let somme_fournisseurs = 0;
  for (let i = 0; i < fournisseurs.length; i++) {
    somme_fournisseurs += fournisseurs[i];
  }

  const somme_3 = somme_emprunts_et_dettes + somme_produits_constatees_avance + somme_autres + somme_avances_acomptes_dettes + somme_fournisseurs;

  // Calcul de la Somme 4

  const somme_4 =
    company.reevaluations.emprunts_et_dettes +
    company.reevaluations.produits_constatees_avance +
    company.reevaluations.autres_dettes +
    company.reevaluations.avances_acomptes_dettes +
    company.reevaluations.fournisseurs;

  const resultat = somme_1 + somme_2 - (somme_3 + somme_4);
  company.valuation_patrimonial = resultat;
  return Math.round(resultat);
};

// Méthode fiscale
export const fiscale = (company) => {
  const calculatedEbitdaFactor = company.fiscale_industry_theoretical_factor * company.fiscale_profitability_theorical_factor * company.fiscale_asset_theorical_factor;
  const ebitdaFactor = company.fiscale_factor_pertinent ? calculatedEbitdaFactor : company.fiscale_given_ebitda_factor;
  const ebe = ebitda(company);
  // const patrimoniale = patrimoniale(company);
  const patrimoniale = 555663; // TODO - finish this
  const valorisation = ebitdaFactor * ebe + (1 - ebitdaFactor) * patrimoniale;
  company.valuation_fiscale = valorisation;
  return valorisation;
};

export const simpleFiscale = (company) => {
  const ebe = company.valuation_ebitda || 0;
  const patrimoniale = company.valuation_patrimonial || 0;

  const details = {
    ebe,
    patrimoniale,
  };

  const valorisation = ebe * company.fiscale_ebe_factor + patrimoniale * company.fiscale_patrimonial_factor;
  company.valuation_fiscale = valorisation;
  return { result: valorisation, details };
};

// Méthode valeur de productivité
export const productivity = (company) => {
  const weightings = [company.productivity_weighting_0, company.productivity_weighting_1, company.productivity_weighting_2, company.productivity_weighting_3];

  const turnovers = [company.turnover_0 || 0, company.turnover_1 || 0, company.turnover_2 || 0, company.turnover_3 || 0];

  const operatingIncomes = [company.operating_income_0 || 0, company.operating_income_1 || 0, company.operating_income_2 || 0, company.operating_income_3 || 0];
  const societyTaxes = [];
  for (let i = 0; i < 4; i++) {
    if (turnovers[i] < 10000000 && operatingIncomes[i] > 42500) {
      societyTaxes.push(42500 * 0.15 + (operatingIncomes[i] - 42500) * company.taxes_rate);
    } else {
      societyTaxes.push(operatingIncomes[i] * 0.15);
    }
  }

  const operatingProfits = [];
  for (let i = 0; i < 4; i++) {
    operatingProfits.push(operatingIncomes[i] - societyTaxes[i]);
  }

  const operatingProfitsAverage =
    operatingProfits[0] * weightings[0] + operatingProfits[1] * weightings[1] + operatingProfits[2] * weightings[2] + operatingProfits[3] * weightings[3];
  company.productivity_operating_profits_average = operatingProfitsAverage;

  const details = {
    operatingIncomes,
    operatingProfits,
    societyTaxes,
    operatingProfitsAverage,
    turnovers,
  };
  if (company.productivity_used_rate == "oat_rate_and_risk_premium") {
    const valorisation = operatingProfitsAverage / (company.productivity_investments_rate + company.productivity_risk_prime);
    company.valuation_productivity = valorisation;
    if (company.productivity_investments_rate + company.productivity_risk_prime === 0) return 0;
    else return { result: valorisation, details };
  } else {
    const valorisation = operatingProfitsAverage / company.productivity_wacc;

    company.valuation_productivity = valorisation;
    if (company.productivity_wacc === 0) return 0;
    else return { result: operatingProfitsAverage / company.productivity_wacc, details };
  }
};

// Méthode capitalisation du bénéfice net moyen
export const netIncomeAverage = (company) => {
  const netIncomeAverage =
    (company.net_income_0 || 0) * (company.net_income_weighting_0 || 0) +
    (company.net_income_1 || 0) * (company.net_income_weighting_1 || 0) +
    (company.net_income_2 || 0) * (company.net_income_weighting_2 || 0) +
    (company.net_income_3 || 0) * (company.net_income_weighting_3 || 0);

  const details = {
    netIncomeAverage,
  };
  company.net_income_average = netIncomeAverage;
  const valorisation = netIncomeAverage * company.net_income_year;
  company.valuation_net_income_average = valorisation;
  return { result: valorisation, details };
};

// Méthode capitalisation de la CAF + trésorerie
export const cashFlowAndCAF = (company) => {
  if (company.caf_investment_need == "Fort") {
    company.caf_investment_need_factor = 1;
  } else if (company.caf_investment_need == "Normal") {
    company.caf_investment_need_factor = 2;
  } else company.caf_investment_need_factor = 3;

  if (company.caf_risk == "Fort") {
    company.caf_risk_factor = 1;
  } else if (company.caf_risk == "Normal") {
    company.caf_risk_factor = 2;
  } else company.caf_risk_factor = 3;

  if (company.caf_stability == "Entreprise peu stable") {
    company.caf_stability_factor = 1;
  } else if (company.caf_stability == "Entreprise stable") {
    company.caf_stability_factor = 2;
  } else company.caf_stability_factor = 3;

  const ebitda_sector_multiples = [0, 11.9, 12.1, 7.9, 8.6, 16.4, 9.3, 11.3, 9.8, 16.5, 11.8, 12.2, 14.8, 8.3, 10.6, 0];
  const filtered_multiples = ebitda_sector_multiples.filter((val) => val !== 0);
  const ebitda_sector_multiples_average = filtered_multiples.reduce((acc, val) => acc + val, 0) / filtered_multiples.length;
  const sectors = [
    "Custom",
    "Biens de consommation",
    "Biens d’équipement",
    "Distribution",
    "Energie / Service publics",
    "Immobilier",
    "Industrie automobile",
    "Matériaux",
    "Média / Communication",
    "Santé / médical",
    "Services aux consommateurs",
    "Services aux entreprises",
    "Technologies de l’information",
    "Télécommunication",
    "Transport & logistique",
    "Services financiers",
  ];

  if (company.caf_sector !== "Custom")
    company.caf_sector_factor = Math.round(2 * (ebitda_sector_multiples[sectors.indexOf(company.caf_sector)] / ebitda_sector_multiples_average) * 1000) / 1000;

  const treasury = company.treasury_3 || 0;
  const averageFactor = company.caf_sector_factor + company.caf_risk_factor + company.caf_stability_factor + company.caf_investment_need_factor;
  const weightings = [company.caf_weighting_0, company.caf_weighting_1, company.caf_weighting_2, company.caf_weighting_3];
  const netIncomes = [company.net_income_0 || 0, company.net_income_1 || 0, company.net_income_2 || 0, company.net_income_3 || 0];
  const dnas = [company.dna_0 || 0, company.dna_1 || 0, company.dna_2 || 0, company.dna_3 || 0];
  const provisions = [
    company.provisions_fixed_assets_0 || 0,
    company.provisions_fixed_assets_1 || 0,
    company.provisions_fixed_assets_2 || 0,
    company.provisions_fixed_assets_3 || 0,
  ];
  const mbas = [];
  for (let i = 0; i < 4; i++) {
    if (i === 0) mbas.push(0);
    else mbas.push(netIncomes[i] + dnas[i] + (provisions[i] - provisions[i - 1]));
  }

  const mbasAverage = mbas[0] * weightings[0] + mbas[1] * weightings[1] + mbas[2] * weightings[2] + mbas[3] * weightings[3];

  const details = {
    averageFactor,
    dnas,
    mbasAverage,
    mbas,
    provisions,
  };
  company.caf_average = mbasAverage;
  company.caf_factor = averageFactor;
  const valorisation = mbasAverage * averageFactor + treasury;
  company.valuation_cashflow = valorisation;
  return { result: valorisation, details };
};

// Méthode de la capitalisation de la MBA moyenne
export const mbaAverage = (company) => {
  if (company.mba_loan == "Fort") {
    company.mba_loan_factor = 0.2;
  } else if (company.mba_loan == "Normal") {
    company.mba_loan_factor = 0.125;
  } else if (company.mba_loan == "Faible") {
    company.mba_loan_factor = 0.05;
  } else company.mba_loan_factor = 0;

  if (company.mba_dividends == "Fort") {
    company.mba_dividends_factor = 0.2;
  } else if (company.mba_dividends == "Normal") {
    company.mba_dividends_factor = 0.125;
  } else if (company.mba_dividends == "Faible") {
    company.mba_dividends_factor = 0.05;
  } else company.mba_dividends_factor = 0;

  if (company.mba_other == "Fort") {
    company.mba_other_factor = 0.2;
  } else if (company.mba_other == "Normal") {
    company.mba_other_factor = 0.125;
  } else if (company.mba_other == "Faible") {
    company.mba_other_factor = 0.05;
  } else company.mba_other_factor = 0;

  const averageFactor = 1 - (company.mba_loan_factor + company.mba_dividends_factor + company.mba_other_factor);
  const weightings = [company.mba_weighting_0, company.mba_weighting_1, company.mba_weighting_2, company.mba_weighting_3];

  const netIncomes = [company.net_income_0 || 0, company.net_income_1 || 0, company.net_income_2 || 0, company.net_income_3 || 0];
  const dnas = [company.dna_0 || 0, company.dna_1 || 0, company.dna_2 || 0, company.dna_3 || 0];
  const provisions = [
    company.provisions_fixed_assets_0 || 0,
    company.provisions_fixed_assets_1 || 0,
    company.provisions_fixed_assets_2 || 0,
    company.provisions_fixed_assets_3 || 0,
  ];
  const mbas = [];
  for (let i = 0; i < 4; i++) {
    if (i === 0) mbas.push(0);
    else mbas.push(netIncomes[i] + dnas[i] + (provisions[i] - provisions[i - 1]));
  }
  const mbasAverage = mbas[0] * weightings[0] + mbas[1] * weightings[1] + mbas[2] * weightings[2] + mbas[3] * weightings[3];

  const details = {
    dnas,
    averageFactor,
    mbasAverage,
    mbas,
  };
  const valorisation = mbasAverage * averageFactor * company.mba_year;

  company.mba_average = mbasAverage;
  company.mba_factor = averageFactor;
  company.valuation_mba_average = valorisation;

  return { result: valorisation, details };
};

/*

const debugFreeCashFlows = [];


debugFreeCashFlows.push({
      i: i,
      adjustedEbitdaProjection: adujestedEbitdaProjections[i + 4],
      societyTaxes: societyTaxes[i],
      investmentProjections: investmentProjections[i],
      workingCapitalProjections: workingCapitalProjections[i],
      netWorkingCapital: netWorkingCapital[i],
      freeCashFlow: adujestedEbitdaProjections[i + 4] - societyTaxes[i] - investmentProjections[i] - netWorkingCapital[i],
    });

*/
