import React from "react";
import CurrencyInput from "react-currency-input-field";

const STARTING_YEAR = 2020;

const KnownBPTable = ({ values, onChange, computedValues }) => {
  const KEYS = ["turnover", "ebitda", "net_income"];
  const KEYS_LABEL = {
    turnover: "Chiffre d'affaires",
    ebitda: "EBITDA",
    net_income: "Revenu net",
  };

  const years = Array.from(Array(11).keys()).map((i) => STARTING_YEAR + i);

  return (
    <div className="w-full overflow-x-auto">
      <h3 className="font-semibold text-lg mb-2">Business Plan Connu</h3>
      <table className="min-w-full bg-white border rounded-lg border-gray-300 table-fixed">
        <thead>
          <tr className="">
            <th colSpan="1" className="border text-gray-700 font-normal text-sm"></th>
            <th colSpan="4" className="border p-2  text-left text-gray-700 font-normal text-sm">
              {"Bilan passé"}
            </th>
            <th colSpan="7" className="border p-2 text-left text-gray-700 font-normal text-sm">
              {"Bilan prévisionnel"}
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border p-2">{"Année"}</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border p-2 text-xs w-56">{KEYS_LABEL[key]}</td>
              {years.map((year, index) => {
                return (
                  <td key={index} className={`border text-xs px-2`}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`${key}_${index}`]}
                      onValueChange={(_, name, value) => onChange({ ...values, [`${key}_${index}`]: value.float })}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
          {computedValues && (
            <>
              <tr>
                <td className="border p-2 text-xs w-24 bg-white">{"Revenu d'exploitation net"}</td>
                {years.map((year, index) => (
                  <td key={year} className="bg-gray-100 text-xs px-2 text-right border">
                    {computedValues.net_operating_income[index]}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="border p-2 text-xs w-24 bg-white">{"Flux de trésorerie disponible"}</td>
                {years.map((year, index) => (
                  <td key={year} className="bg-gray-100 text-xs px-2 text-right border">
                    {computedValues.free_cash_flow[index]}
                  </td>
                ))}
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default KnownBPTable;
