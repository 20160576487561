/**
 * Valorisation methods for SMEs
 * https://docs.google.com/document/d/1tajhftZ7OMjQR70Wz2V7IVmuudXsehnHKY43uk-LLVs/ebit#heading=h.1u4v3kkk5nx7
 *
 * Translation:
 * Excédent brut d'exploitation (EBE) = Earnings before interest, taxes, depreciation, and amortization (EBITDA)
 * Results d'exploitation = Operating income
 * Resultat net = Net income
 * Trésorerie = Cash flow
 * FTD = Free cash flow
 * BFR = Working capital
 */

const PROJECTION_YEAR = 9;
const HISTORIC_YEAR = 4;
const TOTAL_YEAR = PROJECTION_YEAR + HISTORIC_YEAR;

export const dcf = (company) => {
  const debt = company.debt_3 || 0;
  const treasury = company.treasury_3 || 0;

  const addings = [company.addings_0, company.addings_1, company.addings_2, company.addings_3];
  const adjustments = [company.adjustments_0, company.adjustments_1, company.adjustments_2, company.adjustments_3];
  const turnovers = [
    company.turnover_0 || 0,
    company.turnover_1 || 0,
    company.turnover_2 || 0,
    company.turnover_3 || 0,
    company.turnover_4 || 0,
    company.turnover_5 || 0,
    company.turnover_6 || 0,
    company.turnover_7 || 0,
    company.turnover_8 || 0,
    company.turnover_9 || 0,
    company.turnover_10 || 0,
    company.turnover_11 || 0,
    company.turnover_12 || 0,
  ];

  const turnoversProjections = [];
  const turnoversGrowth = [];

  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (i < 4) {
      turnoversProjections.push(turnovers[i]);
    } else {
      if (turnovers[i]) turnoversProjections.push(turnovers[i]);
      else {
        const a = turnoversProjections[i - 1] * (1 + company.dcf_industry_growth_rate * company.dcf_evolution_factor) * (1 - company.dcf_arithmetic_weight);
        const b = (turnoversProjections[i - 1] + (turnoversProjections[i - 1] - turnoversProjections[i - 2]) * company.dcf_evolution_factor) * company.dcf_arithmetic_weight;
        turnoversProjections.push(a + b);
      }
    }
    company[`turnover_projection_${i}`] = turnoversProjections[i];
    if (turnoversProjections[i] && turnoversProjections[i - 1]) turnoversGrowth.push((turnoversProjections[i] - turnoversProjections[i - 1]) / turnoversProjections[i - 1]);
    else turnoversGrowth.push(0);
  }

  const ebitdas = [
    company.ebitda_0 || 0,
    company.ebitda_1 || 0,
    company.ebitda_2 || 0,
    company.ebitda_3 || 0,
    company.ebitda_4 || 0,
    company.ebitda_5 || 0,
    company.ebitda_6 || 0,
    company.ebitda_7 || 0,
    company.ebitda_8 || 0,
    company.ebitda_9 || 0,
    company.ebitda_10 || 0,
    company.ebitda_11 || 0,
    company.ebitda_12 || 0,
  ];

  const ebitdaMargins = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (i < 4) {
      if (turnovers[i] !== 0) ebitdaMargins.push(1 - (ebitdas[i] + adjustments[i] - addings[i]) / turnovers[i]);
      else ebitdaMargins.push(0);
    } else {
      if (!isNaN(ebitdas[i]) && !isNaN(turnoversProjections[i])) ebitdaMargins.push(1 - ebitdas[i] / turnoversProjections[i]);
      else ebitdaMargins.push(0);
    }
  }

  const ebitdaMarginAverage = ebitdaMargins.slice(0, 4).reduce((a, b) => a + b, 0) / 4;

  const ebitdaMarginProjections = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (i < 4) ebitdaMarginProjections.push(ebitdaMargins[i]);
    else if (i === 4) {
      ebitdaMarginProjections.push(ebitdaMargins[i - 1] - (ebitdaMargins[i - 1] - ebitdaMarginAverage) / 9);
    } else {
      if (ebitdaMarginProjections[i - 2] === 0) ebitdaMarginProjections.push(0);
      else {
        const a =
          (1 - ebitdaMarginAverage) * ebitdaMarginProjections[i - 1] * (1 + (ebitdaMarginProjections[i - 1] / ebitdaMarginProjections[i - 2] - 1) * company.dcf_evolution_factor);
        const b = ebitdaMarginAverage * (ebitdaMarginProjections[i - 1] + (ebitdaMarginProjections[i - 1] - ebitdaMarginProjections[i - 2]) * company.dcf_evolution_factor);
        ebitdaMarginProjections.push(a + b);
      }
    }
  }

  const adujestedEbitdaProjections = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (ebitdas[i]) adujestedEbitdaProjections.push(ebitdas[i] - (adjustments[i] || 0) + (addings[i] || 0));
    else {
      adujestedEbitdaProjections.push(turnoversProjections[i] * ebitdaMarginProjections[i]);
    }
  }

  const dnas = [
    company.dna_0 || 0,
    company.dna_1 || 0,
    company.dna_2 || 0,
    company.dna_3 || 0,
    company.dna_4 || 0,
    company.dna_5 || 0,
    company.dna_6 || 0,
    company.dna_7 || 0,
    company.dna_8 || 0,
    company.dna_9 || 0,
    company.dna_10 || 0,
    company.dna_11 || 0,
    company.dna_12 || 0,
  ];
  const dnaProjections = [];
  const a = turnoversProjections[3] !== 0 ? (dnas[3] * 15) / turnoversProjections[3] : 0;
  const b = turnoversProjections[2] !== 0 ? (dnas[2] * 4) / turnoversProjections[2] : 0;
  const c = turnoversProjections[1] !== 0 ? dnas[1] / turnoversProjections[1] : 0;

  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (dnas[i]) dnaProjections.push(dnas[i]);
    else dnaProjections.push(((a + b + c) * turnoversProjections[i]) / 20);
  }

  const otherCharges = [
    company.other_charges_0 || 0,
    company.other_charges_1 || 0,
    company.other_charges_2 || 0,
    company.other_charges_3 || 0,
    company.other_charges_4 || 0,
    company.other_charges_5 || 0,
    company.other_charges_6 || 0,
    company.other_charges_7 || 0,
    company.other_charges_8 || 0,
    company.other_charges_9 || 0,
    company.other_charges_10 || 0,
    company.other_charges_11 || 0,
    company.other_charges_12 || 0,
  ];
  const otherChargeProjections = [];

  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (otherCharges[i]) otherChargeProjections.push(otherCharges[i]);
    else if (i < 3) otherChargeProjections.push(0);
    else {
      if (turnoversProjections[i - 1] === 0 || turnoversProjections[i - 2] === 0 || turnoversProjections[i - 3] === 0) otherChargeProjections.push(0);
      else {
        const a = (otherChargeProjections[i - 1] * 15) / turnoversProjections[i - 1];
        const b = (otherChargeProjections[i - 2] * 4) / turnoversProjections[i - 2];
        const c = otherChargeProjections[i - 3] / turnoversProjections[i - 3];
        otherChargeProjections.push(((a + b + c) * turnoversProjections[i]) / 20);
      }
    }
  }

  const operatingIncomes = [
    company.operating_income_0 || 0,
    company.operating_income_1 || 0,
    company.operating_income_2 || 0,
    company.operating_income_3 || 0,
    company.operating_income_4 || 0,
    company.operating_income_5 || 0,
    company.operating_income_6 || 0,
    company.operating_income_7 || 0,
    company.operating_income_8 || 0,
    company.operating_income_9 || 0,
    company.operating_income_10 || 0,
    company.operating_income_11 || 0,
    company.operating_income_12 || 0,
  ];

  const operatingIncomeProjections = [];
  for (let i = 0; i < TOTAL_YEAR; i++) {
    if (operatingIncomes[i]) operatingIncomeProjections.push(operatingIncomes[i]);
    else operatingIncomeProjections.push(adujestedEbitdaProjections[i] - dnaProjections[i] - otherChargeProjections[i]);
  }

  const societyTaxes = [];
  for (let i = 4; i < TOTAL_YEAR; i++) {
    if (operatingIncomeProjections[i] < 0) {
      societyTaxes.push(0);
    } else if (turnoversProjections[i] < 10000000 && operatingIncomeProjections[i] > 200000) {
      societyTaxes.push(200000 * 0.19 + (operatingIncomeProjections[i] - 200000) * company.taxes_rate);
    } else {
      societyTaxes.push(operatingIncomeProjections[i] * 0.19);
    }
    console.log(societyTaxes[i]);
  }

  const investments = [
    company.investments_0 || 0,
    company.investments_1 || 0,
    company.investments_2 || 0,
    company.investments_3 || 0,
    company.investments_4 || 0,
    company.investments_5 || 0,
    company.investments_6 || 0,
    company.investments_7 || 0,
    company.investments_8 || 0,
    company.investments_9 || 0,
    company.investments_10 || 0,
    company.investments_11 || 0,
    company.investments_12 || 0,
  ];
  const investmentAverage = company.turnover_0
    ? 0.1 * (company.investments_0 / company.turnover_0)
    : 0 + company.turnover_1
    ? 0.1 * (company.investments_1 / company.turnover_1)
    : 0 + company.turnover_2
    ? 0.3 * (company.investments_2 / company.turnover_2)
    : 0 + company.turnover_3
    ? 0.5 * (company.investments_3 / company.turnover_3)
    : 0;

  const investmentProjections = [];
  for (let i = 4; i < TOTAL_YEAR; i++) {
    if (investments[i]) investmentProjections.push(investments[i]);
    else investmentProjections.push(turnoversProjections[i] * investmentAverage);
  }

  const working_capitals_operating_2 = 0; //-106834; // TOCHECK - where are these values coming from? (case rouge dans Data a remplir sur l'excel)
  const working_capitals_operating_3 = 0; //-70960; // TOCHECK - where are these values coming from? (case rouge dans Data a remplir sur l'excel)
  const rate_working_capitals_operating_2 = turnoversProjections[2] !== 0 ? working_capitals_operating_2 / turnoversProjections[2] : 0;
  const rate_working_capitals_operating_3 = turnoversProjections[3] !== 0 ? working_capitals_operating_3 / turnoversProjections[3] : 0;

  const workingCapitalProjections = [
    company.working_capital_0 || 0,
    company.working_capital_1 || 0,
    company.working_capital_2 || 0,
    company.working_capital_3 || 0,
    company.working_capital_4 || 0,
    company.working_capital_5 || 0,
    company.working_capital_6 || 0,
    company.working_capital_7 || 0,
    company.working_capital_8 || 0,
    company.working_capital_9 || 0,
    company.working_capital_10 || 0,
    company.working_capital_11 || 0,
    company.working_capital_12 || 0,
  ];

  for (let i = 4; i < TOTAL_YEAR; i++) {
    if (!workingCapitalProjections[i]) workingCapitalProjections[i] = turnoversProjections[i] / 6;
  }

  const netWorkingCapital = [workingCapitalProjections[0]];
  for (let i = 1; i < TOTAL_YEAR; i++) {
    netWorkingCapital.push(workingCapitalProjections[i] - workingCapitalProjections[i - 1]);
  }

  const freeCashFlows = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    freeCashFlows.push(adujestedEbitdaProjections[i + 4] - societyTaxes[i] - investmentProjections[i] - netWorkingCapital[i + 4]);
  }

  const terminalValues = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    if (company.dcf_wacc - company.dcf_infinite_growth_rate === 0) terminalValues.push(0);
    else terminalValues.push((freeCashFlows[i] * (1 + company.dcf_infinite_growth_rate)) / (company.dcf_wacc - company.dcf_infinite_growth_rate));
  }

  const actualizedFreeCashFlows = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    if (Math.pow(1 + company.dcf_wacc, i + 1) === 0) actualizedFreeCashFlows.push(0);
    else actualizedFreeCashFlows.push(freeCashFlows[i] / Math.pow(1 + company.dcf_wacc, i + 1));
  }

  const actualizedTerminalValue = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    if (Math.pow(1 + company.dcf_wacc, i + 1) === 0) actualizedTerminalValue.push(0);
    else actualizedTerminalValue.push(terminalValues[i] / Math.pow(1 + company.dcf_wacc, i + 1));
  }

  const low_factor = company.dcf_low_factor || 0.7;
  const high_factor = company.dcf_high_factor || 1.3;

  const equityValuesLow = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    const freeCashFlowSum = actualizedFreeCashFlows.slice(0, i + 1).reduce((a, b) => a + b, 0);
    equityValuesLow.push((freeCashFlowSum + actualizedTerminalValue[i]) * low_factor);
  }
  const equityValues = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    const freeCashFlowSum = actualizedFreeCashFlows.slice(0, i + 1).reduce((a, b) => a + b, 0);
    equityValues.push(freeCashFlowSum + actualizedTerminalValue[i]);
  }
  const equityValuesHigh = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    const freeCashFlowSum = actualizedFreeCashFlows.slice(0, i + 1).reduce((a, b) => a + b, 0);
    equityValuesHigh.push((freeCashFlowSum + actualizedTerminalValue[i]) * high_factor);
  }

  company.dcf_equity_values_low = equityValuesLow;
  company.dcf_equity_values_mid = equityValues;
  company.dcf_equity_values_high = equityValuesHigh;

  //
  const low_weight = company.dcf_low_weight || 0.3;
  const mid_weight = company.dcf_mid_weight || 0.4;
  const high_weight = company.dcf_high_weight || 0.3;

  const finalValuations = [];
  for (let i = 0; i < TOTAL_YEAR - 4; i++) {
    const valuation = low_weight * equityValuesLow[i] + mid_weight * equityValues[i] + high_weight * equityValuesHigh[i];
    finalValuations.push(valuation - (debt - treasury));
  }

  company.valuation_dcf = finalValuations[company.dcf_year];
  company.valuation_dcf_array = freeCashFlows;

  const details = {
    turnoversProjections,
    turnoversGrowth,
    ebitdaMarginAverage,
    ebitdaMarginProjections,
    adujestedEbitdaProjections,
    dnaProjections,
    otherChargeProjections,
    operatingIncomeProjections,
    societyTaxes,
    investmentAverage,
    investmentProjections,
    working_capitals_operating_2,
    working_capitals_operating_3,
    rate_working_capitals_operating_2,
    rate_working_capitals_operating_3,
    workingCapitalProjections,
    netWorkingCapital,
    freeCashFlows,
    terminalValues,
    actualizedFreeCashFlows,
    actualizedTerminalValue,
    low_weight,
    mid_weight,
    high_weight,
    equityValuesLow,
    equityValues,
    equityValuesHigh,
  };

  return { result: finalValuations, details };
};

export const vc = (company) => {
  const maturity = company.maturity_stage || "Seed";
  const MATURITY_STAGE = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];
  const exit_years = [8, 6, 5, 4, 2];
  const act_rates = [0.65, 0.55, 0.5, 0.4, 0.3];

  company.exit_year = exit_years[MATURITY_STAGE.indexOf(maturity)];
  const nb_known_years = company.vc_nb_known_years || 4;
  const nb_years_to_simulate = company.exit_year - nb_known_years;

  const risk_score = company.notation_risk_score || 5;
  const score = company.notation_total_score || 5;

  const arithmetic_weight = company.vc_arithmetic_weight || 0.6;
  const evolution_factor = risk_score > 7.175 ? 1.2 : risk_score > 5.5 ? 1 : 0.7;
  const act_rate = act_rates[MATURITY_STAGE.indexOf(maturity)];

  company.vc_act_rate = act_rate;

  const wanted_funds = company.funds_needed || 0;

  const turnovers = [
    company.turnover_4 || 0,
    company.turnover_5 || 0,
    company.turnover_6 || 0,
    company.turnover_7 || 0,
    company.turnover_8 || 0,
    company.turnover_9 || 0,
    company.turnover_10 || 0,
    company.turnover_11 || 0,
    company.turnover_12 || 0,
  ];

  const turnoversProjections = [];
  const turnoversGrowth = [0];

  turnoversProjections.push(turnovers[0]);
  company.turnover_projection_0 = turnoversProjections[0];
  for (let i = 1; i < nb_known_years; i++) {
    turnoversProjections.push(turnovers[i]);
    if (turnoversProjections[i] && turnoversProjections[i - 1]) turnoversGrowth.push((turnoversProjections[i] - turnoversProjections[i - 1]) / turnoversProjections[i - 1]);
    else turnoversGrowth.push(0);
    company[`turnover_projection_${i}`] = turnoversProjections[i];
  }

  const growthRate = (turnovers, n) => (turnovers[n] - turnovers[n - 1]) / turnovers[n - 1];
  function computeWeightedMean(turnovers, n) {
    const res = growthRate(turnovers, n) * 0.667 + growthRate(turnovers, n - 1) * 0.333;
    if (res > 0) return res;
    if (Math.max(growthRate(turnovers, n), growthRate(turnovers, n - 1)) > 0) return Math.max(growthRate(turnovers, n), growthRate(turnovers, n - 1));
    return "custom";
  }

  let turnover_growth = computeWeightedMean(turnoversProjections, nb_known_years - 1);
  if (turnover_growth == "custom") turnover_growth = company.vc_turnover_growth || 0.1;

  const a = turnoversProjections[nb_known_years - 1] * (1 + turnover_growth * evolution_factor) * (1 - arithmetic_weight);
  const b =
    (turnoversProjections[nb_known_years - 1] + (turnoversProjections[nb_known_years - 1] - turnoversProjections[nb_known_years - 2]) * evolution_factor) * arithmetic_weight;
  turnoversProjections.push(a + b);
  if (turnoversProjections[nb_known_years] && turnoversProjections[nb_known_years - 1])
    turnoversGrowth.push((turnoversProjections[nb_known_years] - turnoversProjections[nb_known_years - 1]) / turnoversProjections[nb_known_years - 1]);
  else turnoversGrowth.push(0);
  company[`turnover_projection_${nb_known_years}`] = turnoversProjections[nb_known_years];

  for (let i = nb_known_years + 1; i < nb_known_years + nb_years_to_simulate; i++) {
    if (turnovers[i]) turnoversProjections.push(turnovers[i]);
    else {
      const a = turnoversProjections[i - 1] * (1 + turnoversGrowth[i - 1] * evolution_factor) * (1 - arithmetic_weight);
      const b = (turnoversProjections[i - 1] + (turnoversProjections[i - 1] - turnoversProjections[i - 2]) * evolution_factor) * arithmetic_weight;
      turnoversProjections.push(a + b);
    }
    if (turnoversProjections[i] && turnoversProjections[i - 1]) turnoversGrowth.push((turnoversProjections[i] - turnoversProjections[i - 1]) / turnoversProjections[i - 1]);
    else turnoversGrowth.push(0);
    company[`turnover_projection_${i}`] = turnoversProjections[i];
  }

  const sector_multiples_q1 = [2.6, 1.2, 12.2, 1.6, 5.3, 2.4, 6.5, 1.2, 1.5, 3.1, 1.2, 2.0, 3.8, 1.7, 2.0, 6.1, 2.9];
  const sector_multiples_q2 = [3.6, 2.0, 21.0, 3.1, 7.9, 6.4, 8.6, 1.5, 2.1, 6.2, 2.4, 2.7, 7.3, 2.6, 3.5, 1.5, 4.2];
  const sector_multiples_q3 = [8.1, 5.3, 28.7, 10.9, 12.2, 11.2, 11.2, 3.8, 3.1, 10.3, 6.8, 7.3, 12.9, 6.8, 6.7, 14.9, 7.6];
  const SECTORS = [
    "AdTech",
    "Bigdata",
    "BioTech",
    "PropTech",
    "FinTech",
    "Santé",
    "IoT",
    "Médias",
    "Transport",
    "Voyage",
    "CleanTech",
    "Données",
    "DeepTech",
    "Alimentation",
    "RH",
    "Cybersécurité",
    "Sécurité",
  ];

  const BUSINESS_MODELS = ["Audience", "Performance", "Commission", "E-commerce", "Production", "Services", "Abonnement"];
  const business_models_q1 = [1.1, 1.7, 2.1, 0.9, 1.5, 0.9, 2.7];
  const business_models_q2 = [1.4, 3.6, 3.9, 2.2, 2.6, 1.5, 4.7];
  const business_models_q3 = [1.6, 4.8, 7.8, 4.5, 7.2, 2.0, 10.8];

  const sector_index = SECTORS.indexOf(company.company_sector);
  const business_model_index = BUSINESS_MODELS.indexOf(company.main_business_model) > -1 ? BUSINESS_MODELS.indexOf(company.main_business_model) : 0;

  const thresholds = {
    C: 0,
    CC: 1,
    CCC: 1.8,
    B: 2.4,
    BB: 2.8,
    BBB: 3.4,
    A: 4.4,
    AA: 5.4,
    AAA: 6.6,
    "AAA+": 9.4,
  };

  // Trouver les seuils capLow et capHigh en fonction du score
  const scoreThresholds = Object.keys(thresholds);
  const index = scoreThresholds.findIndex((threshold) => thresholds[threshold] > score);
  const capLow = thresholds[scoreThresholds[index - 1]];
  const capHigh = thresholds[scoreThresholds[index]];
  // Calcul des pourcentages %Low et %High
  const percentLow = 1 - (score - capLow) / (capHigh - capLow);
  const percentHigh = 1 - percentLow;

  const weighting_points = {
    C: 0.05,
    CC: 0.1,
    CCC: 0.15,
    B: 0.2,
    BB: 0.3,
    BBB: 0.4,
    A: 0.5,
    AA: 0.6,
    AAA: 0.7,
    "AAA+": 0.8,
  };

  const q3_points = percentLow * weighting_points[scoreThresholds[index - 1]] + percentHigh * weighting_points[scoreThresholds[index]];

  const q1_secteur_percent = (1 - q3_points) / 4;
  const q2_secteur_percent = 0.25;
  const q3_secteur_percent = q3_points / 4;
  const q1_bm_percent = (1 - q3_points) / 4;
  const q2_bm_percent = 0.25;
  const q3_bm_percent = q3_points / 4;

  const sector_multiples = [sector_multiples_q1[sector_index], sector_multiples_q2[sector_index], sector_multiples_q3[sector_index]];
  const business_multiples = [business_models_q1[business_model_index], business_models_q2[business_model_index], business_models_q3[business_model_index]];

  const multiple =
    q1_secteur_percent * sector_multiples_q1[sector_index] +
    q2_secteur_percent * sector_multiples_q2[sector_index] +
    q3_secteur_percent * sector_multiples_q3[sector_index] +
    q1_bm_percent * business_models_q1[business_model_index] +
    q2_bm_percent * business_models_q2[business_model_index] +
    q3_bm_percent * business_models_q3[business_model_index];

  company.vc_multiple = multiple;

  let exit_revenue;
  if (nb_years_to_simulate < 1) {
    exit_revenue = turnoversProjections[nb_known_years + nb_years_to_simulate - 1];
  } else {
    exit_revenue = turnoversProjections[nb_known_years + nb_years_to_simulate - 1];
    // exit_revenue = turnoversProjections[nb_years_to_simulate];
  }

  const exit_valuation = exit_revenue * multiple;

  const final_valuation = exit_valuation / (1 + act_rate) ** company.exit_year - wanted_funds;

  const details = {
    score,
    risk_score,
    nb_known_years,
    nb_years_to_simulate,
    a,
    b,
    nb_years_to_simulate,
    turnover_growth,
    turnoversProjections,
    evolution_factor,
    arithmetic_weight,
    act_rate,
    turnoversGrowth,
    index,
    capLow,
    capHigh,
    percentLow,
    percentHigh,
    weighting_points,
    scoreThresholds,
    q3_points,
    sector_multiples,
    business_multiples,
    Q1_Secteur_Percent: q1_secteur_percent,
    Q2_Secteur_Percent: q2_secteur_percent,
    Q3_Secteur_Percent: q3_secteur_percent,
    Q1_BM_Percent: q1_bm_percent,
    Q2_BM_Percent: q2_bm_percent,
    Q3_BM_Percent: q3_bm_percent,
    sector_index,
    business_model_index,
    multiple,
    exit_revenue,
    exit_valuation,
    wanted_funds,
  };

  company.valuation_vc = final_valuation;

  return { result: final_valuation, details };
};

// Méthode Dilutive
export const dilutive = (company) => {
  // Note de l'entreprise
  // Notation
  const score = company.notation_total_score || 6.4;
  // Dilution ciblée par l'entrepreneur.
  const entrepreneurDilution = company.dilution_target / 100 || 1;
  // Montant des fonds recherchés.
  const fundsSought = company.funds_needed || 0;

  // Table des seuils de score et leurs dilutions associées
  const thresholds = [
    { score: 0, dilution: 0.35 },
    { score: 1, dilution: 0.3 },
    { score: 1.8, dilution: 0.28 },
    { score: 2.4, dilution: 0.25 },
    { score: 2.8, dilution: 0.23 },
    { score: 3.4, dilution: 0.2 },
    { score: 4.4, dilution: 0.17 },
    { score: 5.4, dilution: 0.15 },
    { score: 6.6, dilution: 0.1 },
    { score: 9.4, dilution: 0.05 },
  ];

  // Fonction pour trouver les seuils capLow et capHigh en fonction du score
  function findThresholds(score) {
    // Trouve les seuils capLow et capHigh en fonction du score
    let capLow = thresholds[0],
      capHigh = thresholds[thresholds.length - 1];
    // Parcourt la liste des seuils pour trouver les valeurs les plus proches du score donné
    for (let i = 0; i < thresholds.length - 1; i++) {
      // Si le score est supérieur au dernier seuil
      if (score >= thresholds[i].score && score < thresholds[i + 1].score) {
        capLow = thresholds[i];
        capHigh = thresholds[i + 1];
        break;
      }
    }

    // Si le score est supérieur au dernier seuil
    if (score >= thresholds[thresholds.length - 1].score) {
      capLow = capHigh = thresholds[thresholds.length - 1];
    }

    return { capLow, capHigh };
  }

  // Fonction pour calculer la dilution théorique en fonction du score et des seuils
  function calculateDilution(score, capLow, capHigh) {
    // Calcule les pourcentages et la dilution théorique
    let percentLow, percentHigh;

    // Si capLow et capHigh sont égaux, la dilution est entièrement basée sur capLow
    if (capLow.score === capHigh.score) {
      percentLow = 1;
      percentHigh = 0;
    } else {
      percentLow = 1 - (score - capLow.score) / (capHigh.score - capLow.score);
      percentHigh = 1 - percentLow;
    }

    // Calcul de la dilution théorique basée sur les pourcentages des seuils bas et haut
    const dilutionTH = percentHigh * capHigh.dilution + percentLow * capLow.dilution;
    return dilutionTH;
  }

  // Fonction pour calculer la dilution finale
  function calculateFinalDilution(entrepreneurDilution, dilutionTH) {
    return (entrepreneurDilution + dilutionTH) / 2;
  }

  // Fonction pour calculer la valorisation finale
  function calculateValuation(fundsSought, finalDilution, dilutionTH, entrepreneurDilution) {
    const valuationTH = fundsSought / dilutionTH - fundsSought;
    const valuationEntrepreneur = fundsSought / entrepreneurDilution - fundsSought;
    const valuation = fundsSought / finalDilution - fundsSought;
    return { valuationTH, valuationEntrepreneur, valuation };
  }

  // Trouver les seuils capLow et capHigh en fonction du score
  const { capLow, capHigh } = findThresholds(score);

  // Calculer la dilution théorique
  let dilutionTH;
  if (company.dilutive_dilution_th) dilutionTH = company.dilutive_dilution_th;
  else dilutionTH = calculateDilution(score, capLow, capHigh);

  // Calculer la dilution finale
  let finalDilution;
  if (company.dilutive_final_dilution) finalDilution = company.dilutive_final_dilution;
  else finalDilution = calculateFinalDilution(entrepreneurDilution, dilutionTH);

  // Calculer la valorisation finale
  const { valuationTH, valuationEntrepreneur, valuation } = calculateValuation(fundsSought, finalDilution, dilutionTH, entrepreneurDilution);
  //
  const details = { fundsSought, capLow, capHigh, dilutionTH, entrepreneurDilution, finalDilution, valuationTH, valuationEntrepreneur };

  company.valuation_dilutive = valuation;

  return { result: valuation, details };
};

// Méthode Comparative
export const comparative = (company) => {
  //Algo de notation
  const score = company.notation_total_score || 5;
  // company_sector
  const sector = company.company_sector || "FinTech";
  const maturity = company.maturity_stage || "Seed";

  // Détermination du type de round
  let roundType;
  switch (maturity) {
    case "Pre-seed":
      roundType = "Pre-seed";
      break;
    case "Seed":
      roundType = "Seed";
      break;
    case "Early Growth":
      roundType = "Serie A/Bridge";
      break;
    case "Fast Growth":
      roundType = "Serie B";
      break;
    case "Mature Stable":
      roundType = "Serie C/Growth/IPO";
      break;
    default:
      roundType = null;
  }

  company.comparative_round = roundType;

  // Seuils de score et leurs pourcentages attribués
  const thresholds = {
    C: 0,
    CC: 1,
    CCC: 1.8,
    B: 2.4,
    BB: 2.8,
    BBB: 3.4,
    A: 4.4,
    AA: 5.4,
    AAA: 6.6,
    "AAA+": 9.4,
  };

  // Trouver les seuils capLow et capHigh en fonction du score
  const scoreThresholds = Object.keys(thresholds);
  const index = scoreThresholds.findIndex((threshold) => thresholds[threshold] > score);
  const capLow = thresholds[scoreThresholds[index - 1]];
  const capHigh = thresholds[scoreThresholds[index]];
  // Calcul des pourcentages %Low et %High
  const percentLow = 1 - (score - capLow) / (capHigh - capLow);
  const percentHigh = 1 - percentLow;

  const percentages = [0, 0.02, 0.03, 0.05, 0.08, 0.14, 0.22, 0.37, 0.61, 1];

  // Calcul des coefficients min et max
  const coeffMax = percentLow * percentages[index - 1] + percentHigh * percentages[index];
  const coeffMin = 1 - coeffMax;

  // Multiples de secteurs
  const sectorMultiples = {
    AdTech: { min: 2.6, q2: 3.6, max: 8.1 },
    Bigdata: { min: 1.2, q2: 2.0, max: 5.3 },
    BioTech: { min: 12.2, q2: 21.0, max: 28.7 },
    PropTech: { min: 1.6, q2: 3.1, max: 10.9 },
    FinTech: { min: 5.3, q2: 7.9, max: 12.2 },
    Santé: { min: 2.4, q2: 6.4, max: 11.2 },
    IoT: { min: 6.5, q2: 8.6, max: 11.2 },
    Médias: { min: 1.2, q2: 1.5, max: 3.8 },
    Transport: { min: 1.5, q2: 2.1, max: 3.1 },
    Voyage: { min: 3.1, q2: 6.2, max: 10.3 },
    CleanTech: { min: 1.2, q2: 2.4, max: 6.8 },
    Données: { min: 2.0, q2: 2.7, max: 7.3 },
    DeepTech: { min: 3.8, q2: 7.3, max: 12.9 },
    Alimentation: { min: 1.7, q2: 2.6, max: 6.8 },
    RH: { min: 2.0, q2: 3.5, max: 6.7 },
    Cybersécurité: { min: 6.1, q2: 6.1, max: 14.9 },
    Sécurité: { min: 2.9, q2: 4.2, max: 7.6 },
  };

  // Object.keys(sectorMultiples).forEach((sector) => {
  //   sectorMultiples[sector].q2 = (sectorMultiples[sector].min + sectorMultiples[sector].max) / 2;
  // });

  const sectorData = sectorMultiples[sector];
  if (!sectorData) {
    throw new Error(`Le secteur "${sector}" n'existe pas dans la base de données des multiples.`);
  }

  const sectorMultiple = sectorData.q2;

  // Multiples d'ajustement de la valorisation finale
  const adjustmentMultiples = {
    "0x-2.25x": 1,
    "2.25x-3.5x": 1.1,
    "3.5x-6.85x": 1.2,
    "6.85x-9x": 1.4,
    ">9x": 5,
  };

  // Trouver le multiple de secteur approprié
  const adjustmentMultiple = Object.entries(adjustmentMultiples).find(([range]) => {
    if (range === ">9x") {
      return sectorMultiple > parseFloat(range.replace(">", "").replace("x", ""));
    } else {
      const [min, max] = range.split("-").map((x) => parseFloat(x.replace("x", "")));
      return sectorMultiple >= min && sectorMultiple < max;
    }
  })[1];

  // Plages de valorisation de marché en fonction du type de round
  const valuationRanges = {
    "Pre-seed": [400000, 2800000],
    Seed: [800000, 8000000],
    "Serie A/Bridge": [5000000, 40000000],
    "Serie B": [12000000, 80000000],
    "Serie C/Growth/IPO": [50000000, 130000000],
  };

  // Détermination des valeurs min et max pour le type de round
  const [minVal, maxVal] = valuationRanges[roundType];

  // Calcul de la valorisation finale
  const valuation = minVal * coeffMin + maxVal * coeffMax;
  const valuation_with_sectors = valuation * adjustmentMultiple;

  const low_factor = company.comparative_low_factor || 0.9;
  const high_factor = company.comparative_high_factor || 1.1;

  const low_valuation = valuation * low_factor;
  const high_valuation = valuation * high_factor;

  const low_valuation_with_sectors = valuation_with_sectors * low_factor;
  const high_valuation_with_sectors = valuation_with_sectors * high_factor;

  const low_weight = company.comparative_low_weight || 0.3;
  const mid_weight = company.comparative_mid_weight || 0.4;
  const high_weight = company.comparative_high_weight || 0.3;

  const final_valuation_without_sectors = low_weight * low_valuation + mid_weight * valuation + high_weight * high_valuation;
  const final_valuation_with_sectors = low_weight * low_valuation_with_sectors + mid_weight * valuation_with_sectors + high_weight * high_valuation_with_sectors;

  company.valuation_comparative_without_sectors = final_valuation_without_sectors;
  company.valuation_comparative_with_sectors = final_valuation_with_sectors;

  // Détails des calculs
  const details = {
    score,
    maturity,
    roundType,
    index,
    percentages,
    capLow,
    capHigh,
    percentLow,
    percentHigh,
    coeffMin,
    coeffMax,
    sector,
    sectorMultiple,
    adjustmentMultiple,
    minVal,
    maxVal,
    low_valuation,
    high_valuation,
    low_valuation_with_sectors,
    high_valuation_with_sectors,
    valuation,
    valuation_with_sectors,
    high_valuation,
  };

  return { result: [final_valuation_without_sectors, final_valuation_with_sectors], details };
};

// Méthode ScoreCard
export const scorecard = (company) => {
  // Définition des multiples de secteur

  const sectorMultiples = {
    AdTech: { min: 2.6, q2: 3.6, max: 8.1 },
    Bigdata: { min: 1.2, q2: 2.0, max: 5.3 },
    BioTech: { min: 12.2, q2: 21.0, max: 28.7 },
    PropTech: { min: 1.6, q2: 3.1, max: 10.9 },
    FinTech: { min: 5.3, q2: 7.9, max: 12.2 },
    Santé: { min: 2.4, q2: 6.4, max: 11.2 },
    IoT: { min: 6.5, q2: 8.6, max: 11.2 },
    Médias: { min: 1.2, q2: 1.5, max: 3.8 },
    Transport: { min: 1.5, q2: 2.1, max: 3.1 },
    Voyage: { min: 3.1, q2: 6.2, max: 10.3 },
    CleanTech: { min: 1.2, q2: 2.4, max: 6.8 },
    Données: { min: 2.0, q2: 2.7, max: 7.3 },
    DeepTech: { min: 3.8, q2: 7.3, max: 12.9 },
    Alimentation: { min: 1.7, q2: 2.6, max: 6.8 },
    RH: { min: 2.0, q2: 3.5, max: 6.7 },
    Cybersécurité: { min: 6.1, q2: 6.1, max: 14.9 },
    Sécurité: { min: 2.9, q2: 4.2, max: 7.6 },
  };

  //DATA à récup dans company
  const maturity = company.maturity_stage || "Seed";
  const score = company.notation_total_score || 5;
  // company_sector
  const sector = company.company_sector || "FinTech";

  // Récupération du multiple du secteur spécifique
  const sectorMultiple = sectorMultiples[sector]?.q2;

  // Détermination de la valorisation de référence en fonction de la maturité
  const referenceValuations = {
    "Pre-seed": 1000000,
    Seed: 1950000,
    "Early Growth": 2500000,
    "Fast Growth": 20000000,
    "Mature Stable": 25000000,
  };
  // Définition des ajustements de multiple de secteur
  const sectorAdjustments = {
    "0x-2.25x": 1,
    "2.25x-3.5x": 1.1,
    "3.5x-6.85x": 1.2,
    "6.85x-9x": 1.4,
    ">9x": 5,
  };
  // Définition des ajustements en fonction des notes
  const noteThresholds = {
    C: { score: 0, adjustment: -0.9 },
    CC: { score: 1, adjustment: -0.7 },
    CCC: { score: 1.8, adjustment: -0.6 },
    B: { score: 2.4, adjustment: -0.5 },
    BB: { score: 2.8, adjustment: -0.35 },
    BBB: { score: 3.4, adjustment: -0.25 },
    A: { score: 4.4, adjustment: 0 },
    AA: { score: 5.4, adjustment: 0.3 },
    AAA: { score: 6.6, adjustment: 1.3 },
    "AAA+": { score: 9.4, adjustment: 1.6 },
  };

  // Valorisation de référence basée sur la maturité
  const valuationRef = referenceValuations[maturity];
  // Détermination des seuils et ajustements associés
  const scores = Object.values(noteThresholds);

  let capLow, capHigh, percentLow, percentHigh, adjustmentLow, adjustmentHigh;

  // Trouver les seuils capLow et capHigh en fonction du score
  for (let i = 0; i < scores.length; i++) {
    if (score <= scores[i].score) {
      capHigh = scores[i].score;
      adjustmentHigh = scores[i].adjustment;
      capLow = scores[i - 1] ? scores[i - 1].score : 0;
      adjustmentLow = scores[i - 1] ? scores[i - 1].adjustment : scores[0].adjustment;
      break;
    }
  }

  // Calcul des pourcentages %High et %Low
  percentHigh = (score - capLow) / (capHigh - capLow);
  percentLow = 1 - percentHigh;

  // Calcul de l'ajustement final en multipliant les pourcentages par les ajustements respectifs
  const adjustment = percentHigh * adjustmentHigh + percentLow * adjustmentLow;

  const adjustmentMultiple = Object.entries(sectorAdjustments).find(([range]) => {
    if (range === ">9x") {
      return sectorMultiple > parseFloat(range.replace(">", "").replace("x", ""));
    } else {
      const [min, max] = range.split("-").map((x) => parseFloat(x.replace("x", "")));
      return sectorMultiple >= min && (max ? sectorMultiple < max : true);
    }
  })[1];

  // Calcul de la valorisation finale en multipliant la valorisation de référence par l'ajustement et l'ajustement multiple
  const valuation = valuationRef * (1 + adjustment);
  const valuation_with_sectors = valuation * adjustmentMultiple;

  const low_factor = company.scorecard_low_factor || 0.9;
  const high_factor = company.scorecard_high_factor || 1.1;

  const low_valuation = valuation * low_factor;
  const high_valuation = valuation * high_factor;

  const low_valuation_with_sectors = valuation_with_sectors * low_factor;
  const high_valuation_with_sectors = valuation_with_sectors * high_factor;

  const low_weight = company.scorecard_low_weight || 0.3;
  const mid_weight = company.scorecard_mid_weight || 0.4;
  const high_weight = company.scorecard_high_weight || 0.3;

  const final_valuation_without_sectors = low_weight * low_valuation + mid_weight * valuation + high_weight * high_valuation;
  const final_valuation_with_sectors = low_weight * low_valuation_with_sectors + mid_weight * valuation_with_sectors + high_weight * high_valuation_with_sectors;

  company.valuation_scorecard_without_sectors = final_valuation_without_sectors;
  company.valuation_scorecard_with_sectors = final_valuation_with_sectors;

  // Détails des calculs
  const details = {
    maturity,
    score,
    capLow,
    capHigh,
    percentLow,
    percentHigh,
    valuationRef,
    adjustment,
    sectorMultiple,
    adjustmentMultiple,
    low_weight,
    mid_weight,
    high_weight,
    low_valuation,
    valuation,
    high_valuation,
    low_valuation_with_sectors,
    valuation_with_sectors,
    high_valuation_with_sectors,
  };

  return {
    result: [final_valuation_without_sectors, final_valuation_with_sectors],
    details,
  };
};

// Méthode Berkus
export const berkus = (company) => {
  //DATA à récup dans company
  const maturity = company.maturity_stage || "Seed";

  // ALGO DE NOTATION
  // Scores attribués pour chaque critère d'analyse
  const scores = {
    PV: 6, // Product validation
    AV: 5.5, // Avantage concurrentiel
    FT: 5, // Faisabilité technique
    TS: 6, // Team skills
    GS: 5.5, // Growth strategy
    SB: 6, // Strategic backing
    FS: 5, // Financial strength
    TLP: 6, // Tech & Legal protection
    MSize: 5.5, // Market Size
    DT: 5, // Disruptive Tech
    MShare: 6, // Market Share
    AK: 5.5, // Accès aux K
  };

  // Définition des seuils pour chaque critère d'analyse
  const thresholds = {
    PV: { low: 5, high: 7 },
    AV: { low: 4, high: 6.5 },
    FT: { low: 4, high: 6.5 },
    TS: { low: 5, high: 7 },
    GS: { low: 4, high: 7 },
    SB: { low: 4.5, high: 7 },
    FS: { low: 4, high: 7 },
    TLP: { low: 4, high: 7 },
    MSize: { low: 4, high: 7 },
    DT: { low: 4, high: 7 },
    MShare: { low: 4, high: 7 },
    AK: { low: 4, high: 7 },
  };

  // Calcul du nombre de crédits pour chaque critère
  function calculateCredit(note, low, high) {
    if (note <= low) return 0; // Aucun crédit si la note est inférieure ou égale au seuil bas
    if (note >= high) return 1; // Crédit entier si la note est supérieure ou égale au seuil haut
    return (note - low) / (high - low); // Crédit partiel proportionnel
  }

  // Groupes de critères pour chaque pilier
  const pillars = {
    SI: ["PV", "AV", "MSize"], // Sound Idea
    P: ["FT", "TLP", "DT"], // Prototype
    QMT: ["TS"], // Quality Management Team
    SR: ["GS", "SB", "MShare"], // Strategic relationships
    RS: ["PV", "FS", "AK"], // Rollout or sales
  };

  // Calcul du crédit pour chaque pilier
  function calculatePillarCredit(pillar) {
    const criteria = pillars[pillar];
    const credits = criteria.map((criterion) => calculateCredit(scores[criterion], thresholds[criterion].low, thresholds[criterion].high));
    const pillarCredit = credits.reduce((acc, credit) => acc + credit, 0) / criteria.length;
    return pillarCredit;
  }

  // Store the pillar credits in an object
  const pillarCredits = {};
  ["SI", "P", "QMT", "SR", "RS"].forEach((pillar) => {
    pillarCredits[pillar] = calculatePillarCredit(pillar);
  });

  company.berkus_idea_weight = pillarCredits["SI"];
  company.berkus_prototype_weight = pillarCredits["P"];
  company.berkus_team_weight = pillarCredits["QMT"];
  company.berkus_partners_weight = pillarCredits["SR"];
  company.berkus_finance_weight = pillarCredits["RS"];

  // Définition des valeurs de crédit en fonction de la maturité de l'entreprise
  const creditValues = {
    "Pre-seed": 500000,
    Seed: 500000,
    "Early Growth": 750000,
    "Fast Growth": 1000000,
    "Mature Stable": 1000000,
  };
  // Valeur d'un crédit basée sur la maturité
  const creditValue = company.berkus_credit_value || creditValues[maturity];

  company.berkus_idea_value = creditValue * pillarCredits["SI"];
  company.berkus_prototype_value = creditValue * pillarCredits["P"];
  company.berkus_team_value = creditValue * pillarCredits["QMT"];
  company.berkus_partners_value = creditValue * pillarCredits["SR"];
  company.berkus_finance_value = creditValue * pillarCredits["RS"];

  const totalCredits = Object.values(pillarCredits).reduce((acc, pillarCredit) => acc + pillarCredit, 0);
  // Calcul de la valorisation finale
  const valuation = totalCredits * creditValue;

  company.valuation_berkus = valuation;

  const details = {
    scores,
    thresholds,
    pillars,
    pillarCredits,
    creditValues,
    creditValue,
    totalCredits,
  };

  return { result: valuation, details };
};

// Méthode StepUp
export const stepup = (company) => {
  //DATA à récup dans company
  const maturity = company.maturity_stage || "Seed";

  const creditValue = company.stepup_credit_value || 250000;

  const everyQuestion = maturity === "Early Growth" || maturity === "Fast Growth" || maturity === "Mature Stable";

  const totalCredits =
    Number(company.stepup_tam) +
    Number(company.stepup_scalability) +
    Number(company.stepup_founders_background) +
    Number(company.stepup_full_time_founders) +
    Number(company.stepup_mvp_developed) +
    Number(company.stepup_business_model_validated) +
    Number(company.stepup_strategic_partnerships) +
    Number(company.stepup_founders_experience_industry) +
    Number(company.stepup_patents) +
    Number(company.stepup_competitive_environment) +
    Number(company.stepup_recurring_revenue) * everyQuestion +
    Number(company.stepup_internalized_activities) * everyQuestion +
    Number(company.stepup_revenue_growth) * everyQuestion +
    Number(company.stepup_notable_clients) * everyQuestion +
    Number(company.stepup_geo_diversification) * everyQuestion +
    Number(company.stepup_media_visibility) * everyQuestion;

  // Calcul de la valorisation finale
  const valuation = totalCredits * creditValue;

  company.valuation_stepup = valuation;

  const details = {
    maturity,
    creditValue,
    totalCredits,
  };

  return { result: valuation, details };
};
