import React, { useState } from "react";

const STARTING_YEAR = 2020;

const NwcTable = ({ values, computedValues }) => {
  const KEYS = ["working_capital"];
  const KEYS_LABEL = {
    working_capital: "Besoin en fonds de roulement",
  };

  const years = Array.from(Array(11).keys()).map((i) => STARTING_YEAR + i);

  const [data, setData] = useState([
    { id: 1, title: "Besoin en fonds de roulement", y2019: "", y2020: "", y2021: "", y2022: "", y2023: "", y2024: "", y2025: "", y2026: "", y2027: "", y2028: "", y2029: "" },
    { id: 2, title: "Variation du BFR", y2019: "", y2020: "", y2021: "", y2022: "", y2023: "", y2024: "", y2025: "", y2026: "", y2027: "", y2028: "", y2029: "" },
  ]);

  const handleCellChange = (id, field, value) => {
    setData((prevData) => prevData.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
  };

  const getCellClassName = (index, year) => {
    const isGrayBackground = index == data.length - 1;

    const isYellowBackground = index < data.length - 1 && (year === "y2022" || year === "y2023" || year === "y2024" || year === "y2025" || year === "y2026");
    return `border p-2 ${isGrayBackground ? "bg-gray-100" : isYellowBackground ? "bg-green-200" : ""}`;
  };

  return (
    <div className="w-full overflow-x-auto">
      <h3 className="font-semibold text-lg mb-2">BFR</h3>
      <table className="min-w-full bg-white border rounded-lg border-gray-300 table-fixed">
        <thead>
          <tr className="">
            <th colSpan="1" className="border text-gray-700 font-normal text-sm"></th>
            <th colSpan="4" className="border p-2  text-left text-gray-700 font-normal text-sm">
              {"Bilan passé"}
            </th>
            <th colSpan="6" className="border p-2 text-left text-gray-700 font-normal text-sm">
              {"Bilan prévisionnel"}
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border p-2">{"Année"}</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border p-2 text-xs w-56">{KEYS_LABEL[key]}</td>
              {years.map((year, index) => (
                <td key={year} className={`border text-xs px-2`}>
                  {values[`${key}_${index}`]}
                </td>
              ))}
            </tr>
          ))}
          {computedValues && (
            <tr>
              <td className="border p-2 text-xs w-56">{"Variation du BFR"}</td>
              {years.map((year, index) => (
                <td key={year} className="bg-gray-100 text-xs px-2 text-right border">
                  {computedValues.net_working_capital[index]}
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NwcTable;
