import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";

import Modal from "../../../components/Modal";
import { dcf, vc, dilutive, comparative, scorecard, berkus, stepup } from "../../../valuations/start-up";

export const DcfModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(dcf(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const [showAdjustments, setShowAdjustments] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(dcf(values));
  }, [values]);
  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(4).keys());
  const PROJECTION_YEARS = Array.from(Array(9).keys());

  const updateAdjustments = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`adjustments_${i}`] =
        values[`company_rent_${i}`] + values[`leaders_salaries_${i}`] + values[`participation_${i}`] + values[`rents_leases_${i}`] + values[`stock_depreciations_${i}`];
    });
    return values;
  };

  const updateAddings = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`addings_${i}`] = values[`mean_rent_${i}`] + values[`mean_leader_salary_${i}`] + values[`rents_leases_DA_${i}`];
    });
    return values;
  };

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode DCF</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Chiffre d'affaires</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`turnover_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`turnover_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`ebitda_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`ebitda_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Retraitements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`adjustments_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer de l'entreprise</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`company_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`company_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire des dirigeants</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`leaders_salaries_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`leaders_salaries_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Participation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`participation_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`participation_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`rents_leases_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Dépréciations sur les stocks</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`stock_depreciations_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`stock_depreciations_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Ajouts</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`addings_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer Moyen</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire moyen dirigeant</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_leader_salary_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_leader_salary_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - D&A des crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_DA_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`rents_leases_DA_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dette</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`debt_${i}`]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`debt_${i}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Trésorerie</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`treasury_${i}`]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`treasury_${i}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation retraité</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {valuation.details.adujestedEbitdaProjections[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dotations aux amortissements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`dna_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`dna_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Résultat d'exploitation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`operating_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`operating_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Résultat net</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`net_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`net_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux de croissance moyen de l'industrie (/1)</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_industry_growth_rate}
                    onChange={(e) => setValues({ ...values, dcf_industry_growth_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux de croissance à l'infini (/1)</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_infinite_growth_rate}
                    onChange={(e) => setValues({ ...values, dcf_infinite_growth_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Facteur d'évolution</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_evolution_factor}
                    onChange={(e) => setValues({ ...values, dcf_evolution_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Poids arithmétique</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_arithmetic_weight}
                    onChange={(e) => setValues({ ...values, dcf_arithmetic_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux de l'IS</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.taxes_rate}
                    onChange={(e) => setValues({ ...values, taxes_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">WACC (/1)</td>

                <td className="flex items-center justify-end">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_wacc}
                    onChange={(e) => setValues({ ...values, dcf_wacc: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="block overflow-auto [border-spacing:1rem] w-full table-fixed text-sm">
            <thead>
              <tr>
                <th colSpan={2} />
                {PROJECTION_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-center">Projection</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Chiffre d'affaires
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.turnoversProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`turnover_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Croissance du chiffre d'affaires
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.turnoversGrowth[year + 4].toLocaleString("fr-FR", { style: "percent", minimumFractionDigits: 2 })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Excédent Brut d'Exploitation Retraité
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.adujestedEbitdaProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`ebitda_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Marge EBE
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.ebitdaMarginProjections[year + 4].toLocaleString("fr-FR", { style: "percent", minimumFractionDigits: 2 })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Dotations au amortissements
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.dnaProjections[year + 4].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Autres produits, charges et reprises
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.otherChargeProjections[year + 4].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Résultat d'exploitation
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.operatingIncomeProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`operating_income_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Marge REx
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    -
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Resultat financier
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    -
                  </td>
                ))}
              </tr>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-center">Calcul des FTDs</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Impôt sur les sociétés
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.societyTaxes[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Investissements
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.investmentProjections[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  BFR
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.workingCapitalProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`working_capital_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Variation du BFR
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.netWorkingCapital[year + 4] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  FTD
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.freeCashFlows[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Valeur terminale en fonction des années
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.terminalValues[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-center">Calcul des FTDS actualisés</h3>
                </td>
              </tr>

              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  FTDs actualisés
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.actualizedFreeCashFlows[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Valeurs terminales actualisées
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.actualizedTerminalValue[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-center">Valorisation</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Valeurs Pessimiste des capitaux propres
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.equityValuesLow[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Valeurs Médiane des capitaux propres
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.equityValues[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold pr-2">
                  Valeurs Optimiste des capitaux propres
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.equityValuesHigh[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowAdjustments(!showAdjustments)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Ajustements</h1>

          <span>{showAdjustments ? "▲" : "▼"}</span>
        </button>
        {showAdjustments && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Coefficient valorisation pessimiste</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_low_factor}
                    onChange={(e) => setValues({ ...values, dcf_low_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Coefficient valorisation optimiste</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_high_factor}
                    onChange={(e) => setValues({ ...values, dcf_high_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Pondération basse</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_low_weight}
                    onChange={(e) => setValues({ ...values, dcf_low_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Pondération médiane</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_mid_weight}
                    onChange={(e) => setValues({ ...values, dcf_mid_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Pondération haute</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_high_weight}
                    onChange={(e) => setValues({ ...values, dcf_high_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full flex justify-center mt-4">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <div className="w-full flex justify-between items-center px-10">
              <div className="flex items-center ">
                <label className="font-medium text-gray-700 pr-4">Année</label>
                <select
                  value={values.dcf_year}
                  onChange={(e) => setValues({ ...values, dcf_year: e.target.value })}
                  className="bg-transparent"
                  onClick={(e) => e.stopPropagation()}>
                  <option value={0}>1</option>
                  <option value={1}>2</option>
                  <option value={2}>3</option>
                  <option value={3}>4</option>
                  <option value={4}>5</option>
                  <option value={5}>6</option>
                  <option value={6}>7</option>
                  <option value={7}>8</option>
                  <option value={8}>9</option>
                </select>
              </div>
              <span className="text-lg font-semibold">
                {valuation.result[values.dcf_year < valuation.result.length ? values.dcf_year : 4].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </span>
            </div>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const VcModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(vc(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(vc(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const PROJECTION_YEARS = Array.from(Array(valuation.details.nb_known_years + valuation.details.nb_years_to_simulate).keys());

  const MATURITY_STAGE = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];
  const SECTORS = [
    "Custom",
    "AdTech",
    "Bigdata",
    "BioTech",
    "CleanTech",
    "Cybersécurité",
    "Données",
    "DeepTech",
    "FinTech",
    "Alimentation",
    "Santé",
    "RH",
    "IoT",
    "Médias",
    "Transport",
    "Voyage",
    "PropTech",
    "Sécurité",
  ];
  const BUSINESS_MODELS = ["Audience", "Performance", "Commission", "E-commerce", "Production", "Services", "Abonnement"];

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode VC (CA)</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="block overflow-auto border-separate [border-spacing:1rem] w-full table-fixed my-2">
            <thead>
              <tr>
                <th />
                {PROJECTION_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-center">Projections</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Chiffre d'affaires</td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.turnoversProjections[year]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`turnover_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Croissance du chiffre d'affaires</td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.turnoversGrowth[year]}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Secteur d'activité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.company_sector}
                    onChange={(e) => setValues({ ...values, company_sector: e.target.value })}>
                    {SECTORS.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Business Model</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.main_business_model}
                    onChange={(e) => setValues({ ...values, main_business_model: e.target.value })}>
                    {BUSINESS_MODELS.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Stade de maturité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.maturity_stage}
                    onChange={(e) => setValues({ ...values, maturity_stage: e.target.value })}>
                    {MATURITY_STAGE.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Nombre d'années connues</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.vc_nb_known_years}
                    onChange={(e) => setValues({ ...values, vc_nb_known_years: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Horizon d'exit</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.exit_year}
                    onChange={(e) => setValues({ ...values, exit_year: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Score</td>
                <td className="text-right">
                  <input
                    disabled
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.score}
                    onChange={(e) => setValues({ ...values, notation_total_score: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Score de risque</td>
                <td className="text-right">
                  <input
                    disabled
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.risk_score}
                    onChange={(e) => setValues({ ...values, notation_risk_score: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Poids Arithméthique</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.arithmetic_weight}
                    onChange={(e) => setValues({ ...values, vc_arithmetic_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Facteur d'évolution</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.evolution_factor}
                    onChange={(e) => setValues({ ...values, evolution_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux d'actualisation</td>
                <td className="text-right">
                  <input
                    disabled
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.act_rate}
                    onChange={(e) => setValues({ ...values, act_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Fonds recherchés</td>
                <td>
                  <CurrencyInput
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                    decimalsLimit={2}
                    value={values.funds_needed}
                    onValueChange={(_, name, value) => {
                      setValues({ ...values, funds_needed: value.float });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const DilutiveModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(dilutive(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(dilutive(values));
  }, [values]);

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode Dilutive</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Montant souhaité</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.funds_needed}
                    onChange={(e) => setValues({ ...values, funds_needed: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Dilution souhaitée (en %)</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dilution_target}
                    onChange={(e) => setValues({ ...values, dilution_target: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dilution Théorique</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.dilutionTH}
                    onChange={(e) => setValues({ ...values, dilutive_dilution_th: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dilution finale</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.finalDilution}
                    onChange={(e) => setValues({ ...values, dilutive_final_dilution: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation Théorique</td>
                <td className="text-right">{valuation.details.valuationTH.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation Entrepreneur</td>
                <td className="text-right">{valuation.details.valuationEntrepreneur.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation finale</td>
                <td className="text-right">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const MATURITY_STAGE = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];
const SECTORS = [
  "AdTech",
  "Bigdata",
  "BioTech",
  "CleanTech",
  "Cybersécurité",
  "Données",
  "DeepTech",
  "FinTech",
  "Alimentation",
  "Santé",
  "RH",
  "IoT",
  "Médias",
  "Transport",
  "Voyage",
  "PropTech",
  "Sécurité",
];
export const ComparativeModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(comparative(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showWithout, setShowWithout] = useState(true);
  const [showWith, setShowWith] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(comparative(values));
  }, [values]);

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode Comparative Market</h3>

      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          values.valuation_comparative = valuation.result[values.comparative_select_sectors ? 1 : 0];
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Score</td>
                <td className="text-right">{`${valuation.details.score?.toFixed(2)}/10`}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation min</td>
                <td className="text-right">{valuation.details.minVal.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation max</td>
                <td className="text-right">{valuation.details.maxVal.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Coefficient valo min</td>
                <td className="text-right">{`${valuation.details.coeffMin.toFixed(2)}%`}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Coefficient valo max</td>
                <td className="text-right">{`${valuation.details.coeffMax.toFixed(2)}%`}</td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Secteur d'activité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.company_sector}
                    onChange={(e) => setValues({ ...values, company_sector: e.target.value })}>
                    {SECTORS.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Stade de maturité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.maturity_stage}
                    onChange={(e) => setValues({ ...values, maturity_stage: e.target.value })}>
                    {MATURITY_STAGE.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowWithout(!showWithout)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Sans secteurs</h1>
          <span>{showWithout ? "▲" : "▼"}</span>
        </button>

        {showWithout && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation basse</td>
                <td className="text-right">{valuation.details.low_valuation.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.comparative_low_weight}
                    onChange={(e) => setValues({ ...values, comparative_low_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation moyenne</td>
                <td className="text-right">{valuation.details.valuation.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.comparative_mid_weight}
                    onChange={(e) => setValues({ ...values, comparative_mid_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation haute</td>
                <td className="text-right">{valuation.details.high_valuation.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.comparative_high_weight}
                    onChange={(e) => setValues({ ...values, comparative_high_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation totale</td>
                <td className="text-right font-bold">{valuation.result[0].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowWith(!showWith)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Avec secteurs</h1>
          <span>{showWith ? "▲" : "▼"}</span>
        </button>

        {showWith && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation basse</td>
                <td className="text-right">{valuation.details.low_valuation_with_sectors.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.comparative_low_weight}
                    onChange={(e) => setValues({ ...values, comparative_low_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation moyenne</td>
                <td className="text-right">{valuation.details.valuation_with_sectors.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.comparative_mid_weight}
                    onChange={(e) => setValues({ ...values, comparative_mid_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation haute</td>
                <td className="text-right">{valuation.details.high_valuation_with_sectors.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.comparative_high_weight}
                    onChange={(e) => setValues({ ...values, comparative_high_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation totale</td>
                <td className="text-right font-bold">{valuation.result[1].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <div className="flex justify-around items-center px-10">
              <div className="flex items-center ">
                <label className="font-medium text-gray-700 pr-4">Secteurs</label>
                <select
                  value={(values.comparative_select_sectors || "false").toString()}
                  onChange={(e) =>
                    setValues({ ...values, comparative_select_sectors: e.target.value === "true", valuation_comparative: valuation.result[e.target.value === "true" ? 1 : 0] })
                  }
                  className="bg-transparent"
                  onClick={(e) => e.stopPropagation()}>
                  <option value={false}>Sans</option>
                  <option value={true}>Avec</option>
                </select>
              </div>
              <span className="text-lg font-semibold mx-2">
                {valuation.result[values.comparative_select_sectors ? 1 : 0].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </span>
            </div>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const ScorecardModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(scorecard(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showWith, setShowWith] = useState(true);
  const [showWithout, setShowWithout] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(scorecard(values));
  }, [values]);

  const MATURITY_STAGE = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];
  const SECTORS = [
    "AdTech",
    "Bigdata",
    "BioTech",
    "CleanTech",
    "Cybersécurité",
    "Données",
    "DeepTech",
    "FinTech",
    "Alimentation",
    "Santé",
    "RH",
    "IoT",
    "Médias",
    "Transport",
    "Voyage",
    "PropTech",
    "Sécurité",
  ];

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode ScoreCard</h3>

      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          values.valuation_scorecard = valuation.result[values.scorecard_select_sectors ? 1 : 0];
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Score</td>
                <td className="text-right">{`${valuation.details.score?.toFixed(2)}/10`}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation de référence</td>
                <td className="text-right">{valuation.details.valuationRef.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Ajustement</td>
                <td className="text-right">{`${valuation.details.adjustment.toFixed(2)}%`}</td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Secteur d'activité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.company_sector}
                    onChange={(e) => setValues({ ...values, company_sector: e.target.value })}>
                    {SECTORS.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Stade de maturité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.maturity_stage}
                    onChange={(e) => setValues({ ...values, maturity_stage: e.target.value })}>
                    {MATURITY_STAGE.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowWithout(!showWithout)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Sans secteurs</h1>
          <span>{showWithout ? "▲" : "▼"}</span>
        </button>

        {showWithout && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation basse</td>
                <td className="text-right">{valuation.details.low_valuation.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.scorecard_low_weight}
                    onChange={(e) => setValues({ ...values, scorecard_low_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation moyenne</td>
                <td className="text-right">{valuation.details.valuation.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.scorecard_mid_weight}
                    onChange={(e) => setValues({ ...values, scorecard_mid_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation haute</td>
                <td className="text-right">{valuation.details.high_valuation.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.scorecard_high_weight}
                    onChange={(e) => setValues({ ...values, scorecard_high_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation totale</td>
                <td className="text-right font-bold">{valuation.result[0].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowWith(!showWith)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Avec secteurs</h1>
          <span>{showWith ? "▲" : "▼"}</span>
        </button>

        {showWith && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation basse</td>
                <td className="text-right">{valuation.details.low_valuation_with_sectors.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.scorecard_low_weight}
                    onChange={(e) => setValues({ ...values, scorecard_low_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation moyenne</td>
                <td className="text-right">{valuation.details.valuation_with_sectors.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.scorecard_mid_weight}
                    onChange={(e) => setValues({ ...values, scorecard_mid_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation haute</td>
                <td className="text-right">{valuation.details.high_valuation_with_sectors.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.scorecard_high_weight}
                    onChange={(e) => setValues({ ...values, scorecard_high_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Valorisation totale</td>
                <td className="text-right font-bold">{valuation.result[1].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <div className="flex justify-around items-center px-10">
              <div className="flex items-center ">
                <label className="font-medium text-gray-700 pr-4">Secteurs</label>
                <select
                  value={(values.scorecard_select_sectors || "false").toString()}
                  onChange={(e) => setValues({ ...values, scorecard_select_sectors: e.target.value === "true" })}
                  className="bg-transparent"
                  onClick={(e) => e.stopPropagation()}>
                  <option value={false}>Sans</option>
                  <option value={true}>Avec</option>
                </select>
              </div>
              <span className="text-lg font-semibold mx-2">
                {valuation.result[values.scorecard_select_sectors ? 1 : 0].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </span>
            </div>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const BerkusModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(berkus(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(berkus(values));
  }, [values]);

  const MATURITY_STAGES = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode Berkus</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <>
            <h3 className="text-l font-semibold my-2">Pilliers</h3>
            <table className="w-full table-fixed">
              <tbody>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Sound Idea (basic value, product risk)</td>
                  <td className="text-right">{Number(valuation.details.pillarCredits["P"]).toFixed(2)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Prototype (reducing technology risk)</td>
                  <td className="text-right">{Number(valuation.details.pillarCredits["QMT"]).toFixed(2)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Quality Management Team (reducing execution risk)</td>
                  <td className="text-right">{Number(valuation.details.pillarCredits["RS"]).toFixed(2)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Strategic relationships (reducing market risk and competitive risk)</td>
                  <td className="text-right">{Number(valuation.details.pillarCredits["SI"]).toFixed(2)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Product rollout or sales (reducing financial or production risk)</td>
                  <td className="text-right">{Number(valuation.details.pillarCredits["SR"]).toFixed(2)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-bold">Total</td>
                  <td className="text-right font-bold">{Number(valuation.details.totalCredits).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Stade de maturité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.maturity_stage}
                    onChange={(e) => setValues({ ...values, maturity_stage: e.target.value, berkus_credit_value: null })}>
                    {MATURITY_STAGES.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Valeur d'un crédit</td>

                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.creditValue}
                    onChange={(e) => setValues({ ...values, berkus_credit_value: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const StepupModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(stepup(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(stepup(values));
  }, [values]);

  const MATURITY_STAGES = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode Step-up</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <>
            <h3 className="text-l font-semibold my-2">Critères</h3>
            <table className="w-full table-fixed">
              <tbody>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Le TAM est supérieur à 500 millions</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_tam}
                      onChange={(e) => setValues({ ...values, stepup_tam: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_tam)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Le business model a une bonne scalabilité</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_scalability}
                      onChange={(e) => setValues({ ...values, stepup_scalability: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_scalability)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Les fondateurs ont un bon bagage entrepreneurial</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_founders_background}
                      onChange={(e) => setValues({ ...values, stepup_founders_background: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_founders_background)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Plus d'un fondateur à 100% sur le projet</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_full_time_founders}
                      onChange={(e) => setValues({ ...values, stepup_full_time_founders: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_full_time_founders)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">MVP développé, en route vers le product market fit</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_mvp_developed}
                      onChange={(e) => setValues({ ...values, stepup_mvp_developed: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_mvp_developed)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Business model validé par vente à des clients</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_business_model_validated}
                      onChange={(e) => setValues({ ...values, stepup_business_model_validated: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_business_model_validated)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Paternariats stratégiques signés</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_strategic_partnerships}
                      onChange={(e) => setValues({ ...values, stepup_strategic_partnerships: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_strategic_partnerships)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Les fondateurs ont une expérience particulière dans le domaine</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_founders_experience_industry}
                      onChange={(e) => setValues({ ...values, stepup_founders_experience_industry: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_founders_experience_industry)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Brevets déposés ou barrières à l'entrée significatives</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_patents}
                      onChange={(e) => setValues({ ...values, stepup_patents: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_patents)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Environnement compétitif favorable</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_competitive_environment}
                      onChange={(e) => setValues({ ...values, stepup_competitive_environment: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_competitive_environment)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">La startup génère des revenus récurrents</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_recurring_revenue}
                      onChange={(e) => setValues({ ...values, stepup_recurring_revenue: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_recurring_revenue)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Toutes les compétences nécessaires à l'activité sont internalisées</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_internalized_activities}
                      onChange={(e) => setValues({ ...values, stepup_internalized_activities: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_internalized_activities)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Croissance du chiffre d'affaire sur les trois dernières années d'au moins 25%</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_revenue_growth}
                      onChange={(e) => setValues({ ...values, stepup_revenue_growth: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_revenue_growth)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">La startup a des clients notables</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_notable_clients}
                      onChange={(e) => setValues({ ...values, stepup_notable_clients: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_notable_clients)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">La startup explore d'autres marchés géographiques ou diversification</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_geo_diversification}
                      onChange={(e) => setValues({ ...values, stepup_geo_diversification: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_geo_diversification)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Gain de visibilité notable dans les médias ou réseaux sociaux</td>
                  <td className="text-right">
                    <select
                      className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                      value={values.stepup_media_visibility}
                      onChange={(e) => setValues({ ...values, stepup_media_visibility: e.target.value })}>
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                      <option value="0.5">Crédit partiel</option>
                    </select>
                  </td>
                  <td className="text-right">{Number(values.stepup_media_visibility)}</td>
                </tr>
                <tr className="border-y border-secondary">
                  <td className="text-sm font-semibold">Crédits totaux</td>
                  <td></td>
                  <td className="text-right font-bold">{valuation.details.totalCredits}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Stade de maturité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.maturity_stage}
                    onChange={(e) => setValues({ ...values, maturity_stage: e.target.value })}>
                    {MATURITY_STAGES.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Valeur d'un crédit</td>

                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.creditValue || 0}
                    onChange={(e) => setValues({ ...values, stepup_credit_value: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
