import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { DcfModal, VcModal, DilutiveModal, ComparativeModal, ScorecardModal, BerkusModal, StepupModal } from "../components/StartupModals";

import KnownBPTable from "../components/KnownBpTable";
import EbitdaTable from "../components/EbitdaTable";
import NwcTable from "../components/NwcTable";
import NetTotal from "../components/NetTotal";
import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi";
import toast from "react-hot-toast";
import api from "../../../services/api";

const Valuation = ({ company, setCompany }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpened, setModalOpened] = useState("");

  useEffect(() => {
    if (searchParams.has("modal")) setModalOpened(searchParams.get("modal"));
  }, [searchParams]);

  const handleCloseModal = () => {
    setSearchParams({});
    setModalOpened(null);
  };

  const handleSubmit = async (values) => {
    try {
      const res = await api.put(`/company/${company._id}`, values);
      if (!res.ok) throw res;
      toast.success("Les informations ont été mises à jour");
      setCompany(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de la mise à jour des informations");
    }
  };

  if (!company) return <></>;

  const weighting_total =
    Math.round(company.weighting_dcf * 100) +
    Math.round(company.weighting_vc * 100) +
    Math.round(company.weighting_dilutive * 100) +
    Math.round(company.weighting_comparative * 100) +
    Math.round(company.weighting_scorecard * 100) +
    Math.round(company.weighting_berkus * 100) +
    Math.round(company.weighting_stepup * 100);

  return (
    <div>
      {modalOpened
        ? {
            dcf: <DcfModal company={company} onSubmit={handleSubmit} onClose={handleCloseModal} />,
            vc: <VcModal company={company} onSubmit={handleSubmit} onClose={handleCloseModal} />,
            dilutive: <DilutiveModal company={company} onSubmit={handleSubmit} onClose={handleCloseModal} />,
            comparative: <ComparativeModal company={company} onSubmit={handleSubmit} onClose={handleCloseModal} />,
            scorecard: <ScorecardModal company={company} onSubmit={handleSubmit} onClose={handleCloseModal} />,
            berkus: <BerkusModal company={company} onSubmit={handleSubmit} onClose={handleCloseModal} />,
            stepup: <StepupModal company={company} onSubmit={handleSubmit} onClose={handleCloseModal} />,
          }[modalOpened]
        : null}

      <div className="border border-secondary p-3 rounded-lg flex flex-col items-center">
        <h3 className="font-semibold text-lg">Valorisation finale</h3>
        <p className="text-lg font-bold">{Math.round(company.valuation_global || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
      </div>

      <div className="mt-4">
        <FinancialInfos company={company} onSubmit={handleSubmit} />
      </div>

      <div className="grid grid-cols-4 gap-4 mt-4">
        <Card
          title="Méthode DCF"
          weighting={company.weighting_dcf}
          value={company.valuation_dcf}
          onChange={(value) => handleSubmit({ valuation: true, weighting_dcf: value })}
          link="?modal=dcf"
        />
        <Card
          title="Méthode VC"
          weighting={company.weighting_vc}
          value={company.valuation_vc}
          onChange={(value) => handleSubmit({ valuation: true, weighting_vc: value })}
          link="?modal=vc"
        />
        <Card
          title="Méthode Dilutive"
          weighting={company.weighting_dilutive}
          value={company.valuation_dilutive}
          onChange={(value) => handleSubmit({ valuation: true, weighting_dilutive: value })}
          link="?modal=dilutive"
        />
        <Card
          title="Méthode Comparative Market"
          weighting={company.weighting_comparative}
          value={company.valuation_comparative * !company.comparative_select_sectors + company.valuation_comparative_with_sectors * company.comparative_select_sectors}
          onChange={(value) => handleSubmit({ valuation: true, weighting_comparative: value })}
          link="?modal=comparative"
        />
        <Card
          title="Méthode Scorecard"
          weighting={company.weighting_scorecard}
          value={company.valuation_scorecard * !company.scorecard_select_sectors + company.valuation_scorecard_with_sectors * company.scorecard_select_sectors}
          onChange={(value) => handleSubmit({ valuation: true, weighting_scorecard: value })}
          link="?modal=scorecard"
        />
        <Card
          title="Méthode Berkus"
          weighting={company.weighting_berkus}
          value={company.valuation_berkus}
          onChange={(value) => handleSubmit({ valuation: true, weighting_berkus: value })}
          link="?modal=berkus"
        />
        <Card
          title="Méthode StepUp"
          weighting={company.weighting_stepup}
          value={company.valuation_stepup}
          onChange={(value) => handleSubmit({ valuation: true, weighting_stepup: value })}
          link="?modal=stepup"
        />
        <div className="border border-gray-200 rounded-xl p-4 flex flex-col items-center">
          <h3 className="text-lg font-semibold mb-4 text-center">Pondération totale</h3>
          <div className="space-y-2 w-full px-4">
            {weighting_total > 100 ? (
              <div className="flex flex-col items-center justify-between gap-3">
                <p className="text-lg font-bold text-red-500">{`${weighting_total}%`}</p>
                <p className="text-lg text-red-500">La pondération totale doit être de 100%.</p>
              </div>
            ) : weighting_total < 100 ? (
              <div className="flex flex-col items-center justify-between gap-3">
                <p className="text-lg font-bold text-blue-500">{`${weighting_total}%`}</p>
                <p className="text-lg text-blue-500">La pondération totale doit être de 100%.</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-between gap-3">
                <p className="text-lg font-bold">{`${weighting_total}%`}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FinancialInfos = ({ company, onSubmit }) => {
  const [values, setValues] = useState({ ...company });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setValues(company);
  }, [company]);

  return (
    <div className={`border border-secondary p-3 transition-all rounded-lg ${isOpen ? "border-black" : "hover:border-black"}`}>
      <button onClick={() => setIsOpen(!isOpen)} className="flex justify-between items-center w-full">
        <h3 className="font-semibold text-lg">Informations financières</h3>
        {isOpen ? <HiOutlineMinus className="w-5" /> : <HiOutlinePlus className="w-5" />}
      </button>

      <div className={`space-y-2 overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${isOpen ? "opacity-100 mt-2 " : "h-0 opacity-0"}`}>
        <div className="flex items-center justify-end">
          <button onClick={() => onSubmit(values)} className="primary-button">
            Enregistrer
          </button>
        </div>

        <div className="space-y-4">
          <KnownBPTable values={values} onChange={setValues} />
          <EbitdaTable values={values} />
          <NwcTable values={values} />
          <NetTotal values={values} />
        </div>
      </div>
    </div>
  );
};

const Card = ({ title, weighting, value, onChange, link, disabled }) => (
  <div className="border border-gray-200 rounded-xl p-4 flex flex-col items-center">
    <h3 className="text-lg font-semibold mb-4 text-center">{title}</h3>
    <div className="space-y-2 w-full px-4">
      <div className="flex items-center justify-between">
        <label className="text-lg">Pondération</label>
        <select value={weighting} onChange={(e) => onChange(Number(e.target.value))} className="select w-20 p-2">
          {[0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1].map((v) => (
            <option key={v} value={v}>
              {v.toLocaleString("fr-FR", { style: "percent" })}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center justify-between">
        <label className="text-lg">Valorisation</label>
        <p className="text-lg font-bold">{Math.round(value || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
      </div>
    </div>
    {disabled ? null : (
      <Link to={link} className="primary-button mt-4">
        Voir le details
      </Link>
    )}
  </div>
);

export default Valuation;
